import React from 'react';
import { Label, Button } from 'reactstrap';
import { history } from '../../components/_helpers/history.js';
import { LoadingModal, ModalAlert } from '../../components/Common/Modal/Modal.js';
import { API } from 'aws-amplify';
import family from '../../image/family.png';


export default class MenuPage extends React.Component {

    constructor(props) {
        super(props);
        let accessToken = window.localStorage.getItem('token');
        let name = window.localStorage.getItem('name');
        this.state = {
            userName: name !== null ? name : null,
            accessToken: accessToken,
            hasBookingGroup: false,
            isFinish: false,
            havingError: false,
            errorMsg: ""
        }
    }

    componentDidMount = () => {
        this.loadBookingList();
    }

    async loadBookingList() {
        this.setState({ isLoading: true });
        API
            .post('bookingapi', '/api/bookingGroup/loadDetails', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'queryStringParameters': {
                    'funcCall': 'bookingList'
                }
            })
            .then(response => {
                // console.log(response);
                if (response.data !== null && response.data !== []) {
                    let list = [];
                    list = response.data;
                    this.setState({
                        hasBookingGroup: list.length > 0,
                        isLoading: false,
                        isFinish: true
                    })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    console.log('no authorised: ' + error.response.status)
                    switch (error.response.status) {
                        case 401:
                            console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    console.log('whyyyy')
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    reserveRKSeat = () => {
        // console.log(this.state.hasBookingGroup);
        if (this.state.hasBookingGroup) {
            history.push({
                pathname: "/NewReserveKidsAndAdultsSeats",
                state: { menu: "main" }
            });
        } else {
            history.push({
                pathname: "/NewBookingGroup",
                state: { fromReserve: true, menu: "main" }
            });
        }
    }

    bookingGroupList = () => {
        history.push({
            pathname: "/BookingGroupList",
            state: { menu: "main" }
        });
    }

    reservationsList = () => {
        // const now = new Date().getHours()
        // if (now >= 18) {
        // console.log('We are open')
        history.push({
            pathname: "/ReservationsList",
            state: { menu: "main" }
        });
    }

    close = () => {
        this.setState({ havingError: false })
        history.push({
            pathname: "/OTPPage",
            state: { menu: "main" }
        });
    }

    render() {
        return (
            <div className='div-primary'>
                <LoadingModal isLoading={this.state.isLoading} />
                <ModalAlert showModal={this.state.havingError} modalMessage={this.state.errorMsg} closeModal={() => this.close()} isSuccess={false} />

                <div className='container col-lg-7 col-md-8 col-sm-12 col-12' style={{ padding: '20px 20px 0px 20px' }}>
                    <div>
                        <Label className='label-normal-normal' style={{ color: '#5C5C5C' }}>Hi <strong>{this.state.userName}</strong></Label><br />
                        <Label className='label-normal-normal' style={{ color: '#5C5C5C' }}>How can we help you today?</Label>
                    </div>
                    <div style={{ padding: 20 }}>
                        <div className='col-lg-8 col-md-12 col-sm-12 col-12' style={{ margin: 'auto' }}>
                            <Button className='btn-menu' disabled={!this.state.isFinish} onClick={this.reserveRKSeat}>Book Seats For Rock Kidz Classes</Button>
                        </div>
                        <div className='col-lg-8 col-md-12 col-sm-12 col-12' style={{ margin: 'auto' }}>
                            <Button className='btn-menu' onClick={this.reservationsList}>My Booking Status</Button>
                        </div>
                        <div className='col-lg-8 col-md-12 col-sm-12 col-12' style={{ margin: 'auto' }}>
                            <Button className='btn-menu' onClick={this.bookingGroupList}>My Booking Groups</Button >
                        </div>
                    </div>
                </div>
                <div>
                    <img className='col-lg-6 col-md-10 col-sm-12 col-12' src={family} alt="family" />
                </div>
            </div>
        )
    }
}