import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { history } from './_helpers/history.js';

import './NavMenu.css';
import logo from '../image/logo.svg';
import menu from '../image/menu_icon.svg';
import cross from '../image/cross_menu_icon.svg';


export default class NavMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sideMenuOn: false
        };
    }

    componentWillUnmount() {
        // console.log(this.state.sideMenuOn)
    }

    refreshVersion = (data) => {
        this.NavMenu.refreshVersion(data)
    }

    menuBtnIsClick = () => {
        history.push({
            pathname: "/MenuPage",
            state: { menu: "main" }
        })
    }

    LoadPage = () => {
        if (this.props.menu === "main" && this.state.sideMenuOn) {
            history.push({
                pathname: "/SideMenuPage",
                state: { menu: "side" }
            })
        } else {
            if (!this.state.sideMenuOn) {
                this.setState({ sideMenuOn: true })
                history.push({
                    pathname: "/SideMenuPage",
                    state: { menu: "side" }
                })
            } else {
                this.setState({ sideMenuOn: false })
                history.goBack();
            }
        }
    }

    render() {
        return (
            <Navbar light className='row size' style={{ borderBottom: '1px solid #E5E5E5' }}>
                <div className='col-lg-8 col-md-8 col-sm-9 col-9'>
                    <NavbarBrand onClick={this.menuBtnIsClick}><img src={logo} alt="logo" /></NavbarBrand>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-3 col-3' style={{ textAlign: 'right', paddingTop: 6, paddingRight: 20 }} >
                    {this.state.sideMenuOn && this.props.menu === "side"
                        ? <div style={{ paddingTop: 5 }}><NavbarBrand onClick={this.LoadPage} ><img src={cross} alt="sideon" /></NavbarBrand></div>
                        : <div><NavbarBrand onClick={this.LoadPage}><img src={menu} alt="sideoff" /></NavbarBrand></div>
                    }
                </div>
            </Navbar >
        );
    }
}
