import React from 'react';
import ncc_logo from '../../image/ncc_logo_big.png';
import { Label } from 'reactstrap';
import { Button } from 'react-bootstrap';

export default class Redirect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: 'https://newcreation.org.sg/services-rockkidz-in-person'
        }
    }

    render() {
        return (
            <div style={{ marginTop: 5 }}>
                <div style={{ margin: 5, textAlign: 'center', boxShadow: '0 3px 3px #e0e0d1' }}><img src={ncc_logo} alt="nccLogo" width="100" /></div>
                <div className='row'>
                    <div className='container col-lg-6 col-md-7 col-sm-11 col-11' style={{ paddingLeft: 15, paddingRight: 15 }}>
                        <div className='div-otp'><Label className='label-otp'>Please note that prior booking is no longer required for our in-person Rock Kidz classes. The booking page you are looking for is not available.</Label></div>
                        <div className='div-otp'><Label className='label-otp'>Visit our website for more information.</Label></div>
                        <div style={{ marginTop: 80 }}>
                            <Button className='btn-otp-submit col-lg-9 col-md-9 col-sm-12 col-12' onClick={() => { window.location.href = this.state.url; }}>OK</Button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}