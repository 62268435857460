import React from 'react'
import { Button } from 'react-bootstrap';
import { Label } from 'reactstrap'
import { LoadingModal, ModalAlert } from '../../components/Common/Modal/Modal.js'
import { API } from 'aws-amplify'
import { history } from '../../components/_helpers/history.js';

import emptyList from '../../image/emptyList_icon.svg';
import defaultImg from '../../image/defaultImg.jpg';
import family from '../../image/family.png';

export default class BookingGroupList extends React.Component {

    constructor(props) {
        super(props);
        let accessToken = window.localStorage.getItem('token');

        this.state = {
            isLoading: false,
            havingError: false,
            errorMsg: "",
            isTimeOut: false,
            bookingGroupList: [],
            isDisableAddBtn: false,
            accessToken: accessToken,

            style: {
                container: { border: "1px solid #D7D8DB", borderRadius: 8, padding: 20, margin: '30px auto 30px auto', textAlign: 'center' },
                buttonList: { width: '100%', padding: 15, marginTop: 30 },
                defaultButtonList: { width: '100%', textAlign: 'left', padding: 15, backgroundImage: `url(${defaultImg})`, marginTop: 30 },
                divStyle: { width: '80%' }
            }
        }
    }

    componentDidMount = () => {
        this.loadBookingList();
    }

    async loadBookingList() {
        this.setState({ isLoading: true });

        API
            .post('bookingapi', '/api/bookingGroup/loadDetails', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'queryStringParameters': {
                    'funcCall': 'bookingList'
                }
            })
            .then(response => {
                // console.log(response);
                if (response.data !== null && response.data !== []) {
                    let bookingGroup = [];
                    response.data.forEach(subElement => {
                        var arrayChild = subElement.SelectedChild.split(',');
                        let groupName = subElement.NoofAdultAttend + " adults + " + arrayChild.length + " RK";
                        bookingGroup.push({
                            id: subElement.BookingGroupID,
                            name: subElement.BookingGroupName,
                            group: groupName,
                            isDefault: subElement.IsDefault
                        });
                    });

                    this.setState({
                        bookingGroupList: bookingGroup,
                        isDisableAddBtn: bookingGroup.length >= 5 ? true : false,
                        isLoading: false
                    })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    addNew = () => {
        this.props.history.push('/NewBookingGroup');
    }

    close = () => {
        this.setState({ havingError: false })
        if (this.state.isTimeOut) {
            this.setState({ isTimeOut: false })
            history.push({
                pathname: "/OTPPage",
                state: { menu: "main" }
            });
        }
        else {
            history.push({
                pathname: "/MenuPage",
                state: { menu: "main" }
            });
        }
    }

    render() {
        let state = this.state;
        let func = this;
        return (
            <div className='div-primary'>
                <LoadingModal isLoading={this.state.isLoading} />
                <ModalAlert showModal={this.state.havingError} modalMessage={this.state.errorMsg} closeModal={() => this.close()} isSuccess={false} />

                <div className='container col-lg-6 col-md-8 col-sm-12 col-12' style={{ padding: 20 }}>
                    <div>
                        <Label className='label-title'>My Booking Groups</Label>
                    </div>
                    {this.state.bookingGroupList.length > 0 && this.state.bookingGroupList.map(function (value, index) {
                        return <div key={index}>
                            <Button style={state.style.buttonList} onClick={(e) => func.props.history.push('/BookingGroup?ID=' + value.id + '&mode=view')}>
                                <div className='row' style={{ marginTop: 0 }}>
                                    <Label className='label-normal col-lg-10 col-md-10 col-sm-9 col-9' style={{ textAlign: 'left', lineHeight: 2 }}><b>{value.name}</b><br />{value.group} </Label>
                                    {value.isDefault &&
                                        <Label className='label-normal col-lg-2 col-md-2 col-sm-3 col-3' style={{ color: '#FFC540', display: "flex", justifyContent: "flex-end", alignItems: "center" }}>Default</Label>
                                    }
                                </div>
                            </Button>
                        </div>
                    })
                    }
                    {this.state.bookingGroupList.length === 0 &&
                        <div>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-12' style={state.style.container}>
                                <p style={{ textAlign: 'center', marginTop: 10 }}><img src={emptyList} alt="notice" /></p>
                                <Label className='label-normal'>You do not have any booking groups!<br />To create one, click/tap the button below</Label>
                            </div>
                            <div>
                                <Button className='btn-modal' style={{ width: '100%', padding: 10 }} onClick={this.addNew}>Add New</Button>
                            </div>
                        </div>
                    }
                </div>
                {this.state.bookingGroupList.length === 0 &&
                    <div>
                        <img className='col-lg-6 col-md-10 col-sm-12 col-12' src={family} alt="family" />
                    </div>
                }
                {this.state.bookingGroupList.length > 0 &&
                    <div>
                        <div className='row' style={{ marginTop: 0 }}>
                            <img className='col-lg-6 col-md-10 col-sm-12 col-12' src={family} alt="family" />
                        </div>
                        <div className='row' style={{ justifyContent: 'center', textAlign: 'center' }}>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-12' style={{ borderTop: "1px solid #E5E5E5", padding: 20 }}>
                                <Button className='btn-footer col-lg-6 col-md-8 col-sm-12 col-12' disabled={this.state.isDisableAddBtn} onClick={this.addNew}>Add New</Button>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}