import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../Layout';
import { LoadingModal } from '../Common/Modal/Modal.js';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const [isAuthenticated, setIsAuthenticated] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function checkingAuthenticated() {
            try {
                // console.log("validateAccess")
                let token = window.localStorage.getItem('token');
                const response = await API
                    .post('bookingapi', '/otp/verifyOtp', {
                        'headers': {
                            'Authorization': token
                        },
                        'response': 'true',
                        'queryStringParameters': {
                            'type': "GetRedis"
                        }
                    })
                    .then(response => {
                        if (response.data.Code === 200) {
                            // console.log("true");
                            return "true";
                        } else if (response.data.Code === 401) {
                            // console.log("false");
                            return "false";
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        return "false";
                    });

                // console.log("validateAccess response: " + response);
                setIsAuthenticated(response);
                // return response;
            }
            catch (e) {
                // console.log("ValidateAccess Error: " + e);
                setIsAuthenticated(false);
                // return false;
            }
        }
        checkingAuthenticated();
    }, []);

    return (
        <Route {...rest} render={(props) => (
            window.localStorage.getItem('token')
                ? <div>
                    {
                        (isAuthenticated === "" || isAuthenticated === undefined) &&
                        <LoadingModal isLoading={isLoading} />
                    }
                    {
                        isAuthenticated === "true" &&
                        <Layout> <Component {...props} /> </Layout>
                    }
                    {
                        isAuthenticated === "false" &&
                        <Redirect to={{ pathname: "/", state: { menu: "main" } }} />
                    }
                </div>
                : <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )}
        />
    );
}