import React from 'react'
import { Button } from 'react-bootstrap';
import { Label } from 'reactstrap'
import { LoadingModal, ModalAlert } from '../../components/Common/Modal/Modal.js'
import { API } from 'aws-amplify';
import { history } from '../../components/_helpers/history.js';
import moment from 'moment';

import emptyList from '../../image/emptyList_icon.svg';
import family from '../../image/family.png';


export default class ReservationsList extends React.Component {

    constructor(props) {
        super(props);
        let accessToken = window.localStorage.getItem('token');

        this.state = {
            isLoading: false,
            havingError: false,
            errorMsg: "",
            isTimeOut: false,
            reserveList: [],
            accessToken: accessToken,

            style: {
                container: { border: "1px solid #CDCED2", borderRadius: 8, padding: 20, margin: '30px auto 30px auto', textAlign: 'center' },
                buttonList: { width: '100%', padding: 15, marginTop: 30, textAlign: 'left' }
            }
        }
    }

    componentDidMount = () => {
        this.loadReserveList();
    }

    async loadReserveList() {
        this.setState({ isLoading: true });

        API
            .post('bookingapi', '/api/reservation', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'queryStringParameters': {
                    'funcCall': 'reservation'
                }
            })
            .then(response => {
                // console.log(response);
                if (response.status === 200) {
                    if (response.data !== null && response.data !== []) {
                        let reservationRecords = [];
                        response.data.forEach(subElement => {
                            let date = moment(subElement.EventDate, "MM/DD/YYYY hh:mm:ss A").format("dddd, Do MMMM yyyy");
                            reservationRecords.push({
                                EventDate: date,
                                QueryId: subElement.QueryId,
                                ReservationStatus: subElement.ReservationStatus === 1 ? 'Pending' : (subElement.ReservationStatus === 2 ? 'Successful' : (subElement.ReservationStatus === 3 ? 'Cancelled' : 'Unsuccessful')),
                                ReservationStatusColor: subElement.ReservationStatus === 1 ? '#FFC540' : (subElement.ReservationStatus === 2 ? '#27AE60' : (subElement.ReservationStatus === 3 ? '#FF8943' : '#EF4B48'))
                            });
                        });

                        this.setState({
                            reserveList: reservationRecords,
                            isLoading: false,
                        })
                    }
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    makeReservations = () => {
        this.props.history.push('/NewReserveKidsAndAdultsSeats');
    }

    close = () => {
        this.setState({ havingError: false })
        if (this.state.isTimeOut) {
            this.setState({ isTimeOut: false })
            history.push({
                pathname: "/OTPPage",
                state: { menu: "main" }
            });
        }
        else {
            history.push({
                pathname: "/MenuPage",
                state: { menu: "main" }
            });
        }
    }

    render() {
        let state = this.state;
        let func = this;
        return (
            <div className='div-primary'>
                <LoadingModal isLoading={this.state.isLoading} />
                <ModalAlert showModal={this.state.havingError} modalMessage={this.state.errorMsg} closeModal={() => this.close()} isSuccess={false} />

                <div className='container col-lg-6 col-md-8 col-sm-12 col-12' style={{ padding: 20 }}>
                    <div>
                        <Label className='label-title'>My Booking Status</Label>
                    </div>
                    {this.state.reserveList.length > 0 && this.state.reserveList.map(function (value, index) {
                        return <div key={index}>
                            <Button style={state.style.buttonList} onClick={(e) => func.props.history.push('/ReserveKidsAndAdultsSeats?ID=' + value.QueryId)}>
                                <Label className='label-normal'><b>{value.EventDate}</b></Label><br />
                                <Label className='label-normal' style={{ color: value.ReservationStatusColor }}>{value.ReservationStatus}</Label>
                            </Button>
                        </div>
                    })
                    }
                    {this.state.reserveList.length === 0 &&
                        <div>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-12' style={state.style.container}>
                                <p style={{ textAlign: 'center', marginTop: 10 }}><img src={emptyList} alt="notice" /></p>
                                <Label className='label-normal'>You do not have any bookings!<br />To book seats, please click/tap the button below</Label>
                            </div>
                            <div>
                                <Button className='btn-modal' style={{ width: '100%', padding: 10 }} onClick={this.makeReservations}>Book Seats</Button>
                            </div>
                        </div>
                    }
                </div>
                {this.state.reserveList.length === 0 &&
                    <div>
                        <img className='col-lg-6 col-md-10 col-sm-12 col-12' src={family} alt="family" />
                    </div>
                }
                {this.state.reserveList.length > 0 &&
                    <div>
                        <div className='row' style={{ marginTop: 0 }}>
                            <img className='col-lg-6 col-md-10 col-sm-12 col-12' src={family} alt="family" />
                        </div>
                        {this.state.reserveList.length < 4 &&
                            <div className='row' style={{ justifyContent: 'center', textAlign: 'center' }}>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-12' style={{ borderTop: "1px solid #E5E5E5", padding: 20 }}>
                                    <Button className='btn-footer col-lg-6 col-md-8 col-sm-12 col-12' onClick={this.makeReservations}>Book Seats</Button>
                                </div>
                            </div>
                        }
                    </div>
                }

            </div>
        )
    }
}