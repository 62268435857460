import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import NavMenu from './NavMenu';

export default class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warningTime: 1000 * 60 * 100000,
            signoutTime: 1000 * 60 * 100005,
            showUserInactiveModal: false,
            showBookingDoneModal: false,
            showBookingErrorModal: false,
            isBooking: false,
            hideNavMenu: true,
            Status: 0,
            menu: "main"
        };
    }

    componentDidMount() {
        this.events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        for (var i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }
        this.setTimeout();
    }

    componentDidUpdate(prevProps) {
        if (this.props.children[1].props.location.state !== undefined && prevProps.children[1].props.location.state !== undefined) {
            if (this.props.children[1].props.location.state.menu !== prevProps.children[1].props.location.state.menu) {
                this.setState({ menu: this.props.children[1].props.location.state.menu })
            }
        }
    }

    refreshVersion = (data) => {
        this.NavMenu.refreshVersion(data)
    }

    showNavMenu = () => {
        this.setState({ hideNavMenu: false })
    }

    clearTimeoutFunc = () => {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);

        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
        this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
        this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
        this.clearTimeoutFunc();
        this.setTimeout();
    };

    warn = () => {
        this.setState({ showUserInactiveModal: true })
    };

    render() {
        return (
            <Container fluid className='nogridPadding'>
                <div>
                    <NavMenu ref={el => this.NavMenu = el} menu={this.state.menu} />
                </div>
                <div>
                    {
                        React.Children.map(this.props.children[1], child => {
                            return React.cloneElement(child, {
                                showNavMenu: this.showNavMenu,
                                ref: ref => this._input = ref
                            });
                        }, this)
                    }
                </div>
            </Container>
        )
    }
}