import React from 'react';
import { Form, Col, FormControl, FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './InputControls.css';
import Switch from "react-switch";

function CustomSwitch({ className, overFlowSelect, id, label, help, fgStyle, lblStyle, disabled, ...props }) {
    return (
        <Form.Group className={className} id={id} style={fgStyle}>
            <div className="switch_div">
                {label && <Form.Label as={FormLabel} style={lblStyle} className='lbl_switch'>{label}</Form.Label>}
                <Switch disabled={disabled} className="react-switch" {...props} />
            </div>
            {help && <Form.Text>{help}</Form.Text>}
        </Form.Group>
    );
}

function BootStrapInput({ required, characterCount, id, label, help, smSize, lblStyle, ...props }) {
    if (label) {
        return <div>   <Col as={FormLabel} sm={smSize} style={lblStyle} className='lbl_detail_naming'>{label}{required && <label className='input_controls_required'>*</label>}
            <FormControl id={id} className='lbl_input_font' {...props} />
            {characterCount && <Form.Text className='lbl_input_font' style={{ fontSize: '14px' }}>{characterCount}</Form.Text>}
            {help && <Form.Text>{help}</Form.Text>}
        </Col>
        </div>
    }
    else {
        return <div>
            <FormControl id={id} className='lbl_input_font' {...props} />
            {characterCount && <Form.Text className='lbl_input_font' style={{ fontSize: '14px' }}>{characterCount}</Form.Text>}
            {help && <Form.Text>{help}</Form.Text>}
        </div>
    }
}

function NewBootStrapInput({ className, required, characterCount, id, label, help, fgStyle, lblStyle, validationState, placeholderText, ...props }) {
    if (label) {
        return (
            <Form.Group className={className}>
                <Form.Label as={FormLabel} style={lblStyle} className='lbl_detail_naming'>{label}</Form.Label>
                {required && <label className='input_controls_required'>*</label>}
                <Form.Control style={fgStyle} className='lbl_input_font' isInvalid={validationState} placeholder={placeholderText} {...props} />
                {validationState && <Form.Control.Feedback className='label-small-300' type="invalid" style={{ color: '#dc3545' }}><b>{validationState}</b></Form.Control.Feedback>}
                {characterCount && <Form.Text className='lbl_input_font' style={{ fontSize: '14px' }}>{characterCount}</Form.Text>}
                {help && <Form.Text>{help}</Form.Text>}
            </Form.Group>
        );
    }
    else {
        return <Form.Group className={className}>
            <Form.Control id={id} style={fgStyle} className='lbl_input_font' isInvalid={validationState} placeholder={placeholderText} {...props} />
            {validationState && <Form.Control.Feedback className='label-small-300' type="invalid" style={{ color: '#dc3545' }}><b>{validationState}</b></Form.Control.Feedback>}
            {characterCount && <Form.Text className='lbl_input_font' style={{ fontSize: '14px' }}>{characterCount}</Form.Text>}
            {help && <Form.Text>{help}</Form.Text>}
        </Form.Group>
    }
}



function CustomSelect({ className, required, overFlowSelect, id, label, help, smSize, lblStyle, ...props }) {
    if (!overFlowSelect) {
        return <div className={className} id={id}>  <Col as={FormLabel} sm={smSize} style={lblStyle} className='lbl_detail_naming'>{label} {required && <label className='input_controls_required'>*</label>}
            <Select className='lbl_input_font'  {...props} />
            {help && <Form.Text>{help}</Form.Text>}
        </Col>
        </div>
    }
    else {
        return <div className={className} id='last-Select'>  <Col as={FormLabel} sm={smSize} style={lblStyle} className='lbl_detail_naming'>{label} {required && <label className='input_controls_required'>*</label>}
            <Select className='lbl_input_font'  {...props} />
            {help && <Form.Text>{help}</Form.Text>}
        </Col>
        </div>
    }
}

function NewCustomSelect({ className, required, overFlowSelect, id, label, help, fgStyle, lblStyle, validationState = null, disabled, ...props }) {
    const customStyles = {
        control: (base, state) => ({
            ...base,
            // state.isFocused can display different borderColor if you need it
            borderColor: state.isFocused ?
                '#ddd' : validationState === null || validationState === undefined ?
                    '#ddd' : '#CDCED2',
            // overwrittes hover style
            '&:hover': {
                borderColor: state.isFocused ?
                    '#ddd' : validationState === null || validationState === undefined ?
                        '#ddd' : '#CDCED2'
            }
        })
    }

    if (!overFlowSelect) {
        return (
            <Form.Group className={className} id={id} style={fgStyle}>
                {label && <Form.Label as={FormLabel} style={lblStyle} className='lbl_detail_naming'>{label}</Form.Label>}
                {required && <label className='input_controls_required'>*</label>}
                <Select isDisabled={disabled} className='lbl_input_font' styles={customStyles} {...props} />

                <Form.Control className='lbl_input_font' style={{ display: 'none' }} isInvalid={validationState} {...props} />
                {validationState && <Form.Control.Feedback className='label-small-300' type="invalid" style={{ color: '#dc3545' }}><b>{validationState}</b></Form.Control.Feedback>}
            </Form.Group>
        );
    }
    else {
        return (
            <Form.Group className={className} id='last-Select' style={fgStyle}>
                {label && <Form.Label as={FormLabel} style={lblStyle} className='lbl_detail_naming'>{label}</Form.Label>}
                {required && <label className='input_controls_required'>*</label>}
                <Select isDisabled={disabled} className='lbl_input_font' styles={customStyles}  {...props} />
                <Form.Control className='lbl_input_font' isInvalid={validationState === null ? false : validationState} style={{ display: 'none' }} {...props} />
                {validationState && <Form.Control.Feedback className='label-small-300' type="invalid" style={{ color: '#dc3545' }}><b>{validationState}</b></Form.Control.Feedback>}
            </Form.Group>
        );
    }
}

function CustomDatePicker({ className, required, overFlow, id, label, smSize, help, lblStyle, ...props }) {
    if (!overFlow) {
        return <div className={className}>  <Col as={FormLabel} sm={smSize} style={lblStyle} className='lbl_detail_naming'>{label}  {required && <label className='input_controls_required'>*</label>}
            <DatePicker id={id} className='form-control lbl_input_font' {...props} tyle={{ borderColor: 'red' }} />
            {help && <Form.Text>{help}</Form.Text>}
        </Col>
        </div>
    }
    else {
        return <div className={className}>  <Col as={FormLabel} sm={smSize} style={lblStyle} className='lbl_detail_naming'>{label}  {required && <label className='input_controls_required'>*</label>}
            <div id='tokenGroupCalender'>
                <DatePicker id={id} className='form-control lbl_input_font' {...props} style={{ borderColor: 'red' }} />
            </div>
            {help && <Form.Text>{help}</Form.Text>}
        </Col>
        </div>
    }
}

function NewCustomDatePicker({ className, required, overFlow, id, label, help, fgStyle, validationState, lblStyle, ...props }) {
    const { disabled, selected, onChange, minDate, maxDate, startDate, endDate, calendarClassName, dateFormat, selectsStart, showMonthDropdown, showYearDropdown, placeholderText, ...rest } = props
    if (!overFlow) {
        return (
            <Form.Group className={className} style={fgStyle}>
                {label && <Form.Label as={FormLabel} style={lblStyle} className='lbl_detail_naming'>{label}</Form.Label>}
                {required && <label className='input_controls_required'>*</label>}
                <DatePicker id={id} className={(validationState === null || validationState === undefined) ? 'form-control lbl_input_font' : 'react-datepicker_error form-control lbl_input_font'} {...props} />
                <Form.Control className='lbl_input_font' isInvalid={validationState} style={{ display: 'none' }} {...rest} />
                {validationState && <Form.Control.Feedback type="invalid">{validationState}</Form.Control.Feedback>}
            </Form.Group>
        );
    }
    else {
        return (
            <Form.Group className={className} style={fgStyle}>
                {label && <Form.Label as={FormLabel} style={lblStyle} className='lbl_detail_naming'>{label}</Form.Label>}
                {required && <label className='input_controls_required'>*</label>}
                <div id='tokenGroupCalender'>
                    <DatePicker id={id} className={(validationState === null || validationState === undefined) ? 'form-control lbl_input_font' : 'react-datepicker_error form-control lbl_input_font'} {...props} />
                </div>
                <Form.Control className='lbl_input_font' isInvalid={validationState} style={{ display: 'none' }} {...rest} />
                {validationState && <Form.Control.Feedback type="invalid">{validationState}</Form.Control.Feedback>}
            </Form.Group>
        );
    }
}


export { CustomSwitch, BootStrapInput, CustomSelect, CustomDatePicker, NewBootStrapInput, NewCustomSelect, NewCustomDatePicker }
