import React from 'react';
import queryString from 'query-string';
import { API } from 'aws-amplify'
import { LoadingModal } from '../../components/Common/Modal/Modal.js'
import { history } from '../../components/_helpers/history.js';

export default class MainPage extends React.Component {

    constructor(props) {
        super(props);
        let params = queryString.parse(this.props.location.search);

        this.state = {
            isLoading: false,
            code: typeof params.code !== undefined ? params.code : null,
            base_SSO_URL: encodeURI("https://api-stg.newcreation.org.sg"),
            redirect_url: encodeURI("https://rkbooking-stg.newcreation.org.sg"),
            response_type: encodeURI("code"),
            client_id: encodeURI("b37e8a26-c26a-4183-90a3-16c09c96bebb"),
            scope: encodeURI("profile+noahapi-userprofile+noahapi-member+offline_access+userid+openid+noahapi-authentication")
        }
    }

    componentDidMount = () => {
        this.setState({ isLoading: true });
        if (this.state.code == null) {
            this.setState({ isLoading: false });
            let loginURL = this.state.base_SSO_URL + "/connect/authorize?redirect_uri=" + this.state.redirect_url + "&response_type=" + this.state.response_type + "&client_id=" + this.state.client_id + "&scope=" + this.state.scope;
            window.location = loginURL;
        } else {
            this.CheckSSOAuthAsync();
        }
    }

    async CheckSSOAuthAsync() {
        API
            .post('bookingapi', '/authentication', {
                'response': 'true',
                'body': {
                    'code': this.state.code
                }
            })
            .then(response => {
                // console.log(response)
                this.setState({ isLoading: false });
                if (response.status === 200) {
                    window.localStorage.setItem('token', response.data.access_token);
                    window.localStorage.setItem('name', response.data.userName);
                    window.localStorage.setItem('id_token', response.data.id_token);
                    history.push({
                        pathname: "/",
                        state: { menu: "main" }
                    });
                }
            })
            .catch(error => {               
                this.setState({ isLoading: false });
                console.log(error);
                if (this.state.code == null) {
                    let loginURL = this.state.base_SSO_URL + "/connect/authorize?redirect_uri=" + this.state.redirect_url + "&response_type=" + this.state.response_type + "&client_id=" + this.state.client_id + "&scope=" + this.state.scope;
                    window.location = loginURL;
                } else {
                    history.push({
                        pathname: "/NotFound"
                    });
                }
            });
    }

    render() {
        return (
            <div className='div-primary'>
                <LoadingModal isLoading={this.state.isLoading} />
            </div>
        );
    }
}