import React from 'react';
import queryString from 'query-string';
import { Button } from 'react-bootstrap';
import { Label } from 'reactstrap';
import { LoadingModal, ModalConfirmCancel, ModalAlert } from '../../components/Common/Modal/Modal.js';
import moment from 'moment';
import { API } from 'aws-amplify';
import { history } from '../../components/_helpers/history.js';

import tick from '../../image/tick_icon.svg';

export default class ReservationView extends React.Component {
    constructor(props) {
        super(props);
        let params = queryString.parse(this.props.location.search);
        let accessToken = window.localStorage.getItem('token');

        this.state = {
            queryId: Object.keys(params).length !== 0 ? (typeof params.ID !== undefined || params.ID !== undefined) ? params.ID : null : null,
            accessToken: accessToken,
            isLoading: false,
            reserveId: null,
            reserveDate: null,
            reservationStatus: null,
            reservationStatusColor: null,
            selectedChild: null,
            isShowingMore: false,
            selectedAdultNum: null,
            selectedAdultNumReserved: null,
            selectedChildWithNameNCalss: [],
            selectedVenue: null,
            selectedServiceId: null,
            selectedService: null,
            selectedTiming: null,
            selectedRKStartTime: null,
            clickCancelReserveBtn: false, //cancel reservation
            msgSavingChanges: null,
            titleSavingChanges: null,
            isSuccess: false,
            havingError: false,
            errorMsg: "",
            isTimeOut: false,
            //successful status
            clickReleaseSeatBtn: false,
            disableConfirmBtn: false,
            clickConfirmBtn: false,
            editableNumOfAdult: null,
            editableChildSeat: [],
            emailNeededForEditReservation: false,
            qrCodeNumbers: [],

            style: {
                listBtnStyle: { width: '100%', textAlign: 'left', padding: 15, lineHeight: 2, marginBottom: 20 },
                labelReleaseStyle: { color: '#FF8943', fontWeight: 500, display: "flex", justifyContent: "flex-end", width: '20%' },
                labelUnReleaseStyle: { color: '#56CCF2', fontWeight: 500, display: "flex", justifyContent: "flex-end", width: '20%', textDecoration: 'underline' }
            }
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    async loadData() {
        this.setState({ isLoading: true });
        API
            .post('bookingapi', '/api/reservation', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'body': {
                    'queryId': this.state.queryId
                },
                'queryStringParameters': {
                    'funcCall': 'reservationDetails'
                }
            })
            .then(response => {
                // console.log(response.data);
                if (response.data !== null && response.data !== []) {
                    let date = moment(response.data.EventDate, "MM/DD/YYYY hh:mm:ss A").format("dddd, Do MMMM yyyy");
                    let timing = moment(response.data.ServiceTiming, "MM/DD/YYYY hh:mm:ss A").format('hh:mm A');
                    this.setState({
                        reserveId: response.data.ReservationId,
                        reserveDate: date,
                        reservationStatus: response.data.ReservationStatus,
                        reservationStatusColor: response.data.ReservationStatus === 1 ? '#FFC540' : (response.data.ReservationStatus === 2 ? '#27AE60' : (response.data.ReservationStatus === 3 ? '#FF8943' : '#EF4B48')),
                        selectedServiceId: response.data.EventSessionId,
                        selectedService: response.data.ServiceName,
                        selectedTiming: timing,
                        selectedVenue: response.data.VenueName,
                        selectedRKStartTime: response.data.ServiceTiming,
                        selectedChild: response.data.SelectedChild,
                        selectedAdultNum: response.data.NoofAdultAttend,
                        selectedAdultNumReserved: response.data.NoofAdultReserved,
                        editableNumOfAdult: response.data.NoofAdultReserved,
                        queryId: response.data.QueryId,
                        qrCodeNumbers: response.data.QRCodeNumbers
                    })

                    if (response.data.SelectedChild !== "") {
                        this.loadBookingGroupDetails();
                    } else {
                        this.setState({
                            selectedChildWithNameNCalss: [],
                            isLoading: false
                        })
                    }
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    eventHandler = (id, value) => {
        switch (id) {
            case 'cancelReserveBtn':
                this.setState({
                    clickCancelReserveBtn: true,
                    msgSavingChanges: "Please click confirm to cancel the booking",
                    titleSavingChanges: "Cancel Booking"
                });
                break;

            //for successful status    
            case 'releaseReserveBtn':
                this.setState({ clickReleaseSeatBtn: true, disableConfirmBtn: true })
                break;

            case 'minusNumOfAdultBtn':
                let tempNumofAdult = parseInt(this.state.editableNumOfAdult);
                if (tempNumofAdult !== 0) {
                    tempNumofAdult = tempNumofAdult - 1;
                }
                if (tempNumofAdult !== parseInt(this.state.selectedAdultNumReserved) || (Array.isArray(this.state.editableChildSeat) && this.state.editableChildSeat.length)) {
                    this.setState({
                        disableConfirmBtn: false,
                        editableNumOfAdult: tempNumofAdult
                    })
                }
                break;

            case 'plusNumOfAdultBtn':
                let tempPlusNumofAdult = parseInt(this.state.editableNumOfAdult);
                if (tempPlusNumofAdult !== parseInt(this.state.selectedAdultNumReserved)) {
                    tempPlusNumofAdult = tempPlusNumofAdult + 1;
                }
                if (tempPlusNumofAdult !== parseInt(this.state.selectedAdultNumReserved)) {
                    this.setState({
                        disableConfirmBtn: false,
                        editableNumOfAdult: tempPlusNumofAdult
                    })
                }
                else {
                    this.setState({
                        disableConfirmBtn: (Array.isArray(this.state.editableChildSeat) && this.state.editableChildSeat.length) ? false : true,
                        editableNumOfAdult: tempPlusNumofAdult
                    })
                }
                break;

            case 'releaseChildBtn':
                if (this.state.editableChildSeat.includes(value)) {
                    let tempEditableReleaseChildSeat = this.state.editableChildSeat;
                    let index = tempEditableReleaseChildSeat.indexOf(value);
                    tempEditableReleaseChildSeat.splice(index, 1);

                    this.setState({
                        editableChildSeat: tempEditableReleaseChildSeat,
                        disableConfirmBtn: tempEditableReleaseChildSeat.length === 0 ? (this.state.editableNumOfAdult === this.state.selectedAdultNumReserved ? true : false) : false
                    })
                }
                else {
                    let tempEditableUnReleaseChildSeat = this.state.editableChildSeat;
                    tempEditableUnReleaseChildSeat.push(value);

                    this.setState({
                        editableChildSeat: tempEditableUnReleaseChildSeat,
                        disableConfirmBtn: false
                    })
                }
                break;

            case 'confirmReserveBtn':
                this.setState({
                    clickReleaseSeatBtn: false,
                    clickConfirmBtn: true,
                    msgSavingChanges: "Please click confirm to release the seats",
                    titleSavingChanges: "Confirm Release"
                });
                break;

            default:
                break;
        }
    }

    async loadBookingGroupDetails() {
        // console.log(this.state.selectedChild);
        API
            .post('bookingapi', '/api/childrenList', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'body': {
                    'selectedChild': this.state.selectedChild
                },
                'queryStringParameters': {
                    'funcCall': 'selectedchildrenlist'
                }
            })
            .then(response => {
                // console.log(response.data);
                if (response.data !== null && response.data.length > 0) {
                    this.setState({
                        selectedChildWithNameNCalss: response.data,
                        isLoading: false,
                    }, () => {
                        if (this.state.emailNeededForEditReservation) {
                            this.processedToSendEmail(6);
                        }
                    });
                }
                else {
                    this.setState({
                        selectedChildWithNameNCalss: [],
                        isLoading: false
                    })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    async processedToCancelReservation() {
        this.setState({ isLoading: true });
        API
            .post('bookingapi', '/api/reservation', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'body': {
                    'queryId': this.state.queryId,
                    'reservationId': this.state.reserveId,
                    'sessionId': this.state.selectedServiceId,
                    'noofAdultAttend': this.state.selectedAdultNumReserved
                },
                'queryStringParameters': {
                    'funcCall': 'cancelReservation'
                }
            })
            .then(response => {
                // console.log(response);
                if (response.status === 200) {
                    this.setState({
                        titleSavingChanges: 'Success',
                        msgSavingChanges: 'Your booking has been cancelled',
                        clickCancelReserveBtn: false,
                        isSuccess: true,
                        isLoading: false
                    })
                    this.processedToSendEmail(5);
                } else {
                    this.setState({
                        isLoading: false,
                        havingError: true,
                        errorMsg: "Cancel Booking Unsuccessful"
                    })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    async processedToEditReservation() {
        this.setState({ isLoading: true });
        API
            .post('bookingapi', '/api/reservation', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'body': {
                    'queryId': this.state.queryId,
                    'reservationId': this.state.reserveId,
                    'noofAdultAttend': this.state.editableNumOfAdult,
                    'selectedChild': this.state.editableChildSeat.toString(),
                    'sessionId': this.state.selectedServiceId,
                    'noofAdultRelease': this.state.selectedAdultNumReserved - this.state.editableNumOfAdult
                },
                'queryStringParameters': {
                    'funcCall': 'editReservation'
                }
            })
            .then(response => {
                // console.log(response);
                if (response.status === 200) {
                    this.setState({
                        titleSavingChanges: 'Success',
                        msgSavingChanges: 'You have successfully released the seat(s)',
                        clickConfirmBtn: false,
                        isSuccess: true,
                        editableChildSeat: [],
                        editableNumOfAdult: this.state.editableNumOfAdult,
                        isLoading: false,
                        emailNeededForEditReservation: true
                    });
                }
                else {
                    this.setState({
                        isLoading: false,
                        havingError: true,
                        errorMsg: "No matching children identities found"
                    })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    async processedToSendEmail(emailType) {
        this.setState({
            emailNeededForEditReservation: false
        })
        if (this.state.reservationStatus === 2) {
            let selectedFullName = [];
            let selectedRKClassName = [];
            this.state.selectedChildWithNameNCalss.forEach(obj => {
                selectedFullName.push(obj.ChildName);
                selectedRKClassName.push(obj.RegisteredClassName);
            })
            // convert objec to a query string
            const stringFullName = Object.keys(selectedFullName).map(key => `${selectedFullName[key]}`).join('&');
            const stringRKClassName = Object.keys(selectedRKClassName).map(key => `${selectedRKClassName[key]}`).join('&');
            API
                .post('bookingapi', '/email', {
                    'headers': {
                        'Authorization': this.state.accessToken
                    },
                    'response': 'true',
                    'body': {
                        'NumOfAdults': this.state.selectedAdultNumReserved,
                        'RKChild': stringFullName,
                        'RKClass': stringRKClassName,
                        'SendEmailStatus': emailType,
                        'RequireQRCode': emailType === 5 ? false : true,
                        'QRCodeNumbers': this.state.qrCodeNumbers.toString(),
                        'SessionId': this.state.selectedServiceId,
                        'ScheduleName': this.state.selectedService,
                        'ServiceStartTime': this.state.selectedRKStartTime,
                        'BuildingName': this.state.selectedVenue,
                        'ReservationId': this.state.reserveId
                    }
                })
                .then(response => {
                    // console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    confirm = () => {
        this.setState({ titleSavingChanges: null, msgSavingChanges: null });
        if (this.state.isSuccess) {
            this.setState({ isSuccess: false });
            this.loadData();
        }
        else if (this.state.clickCancelReserveBtn) {
            this.setState({
                editableChildSeat: [],
                editableNumOfAdult: this.state.selectedAdultNumReserved,
                clickConfirmBtn: false,
                clickReleaseSeatBtn: false,
                disableConfirmBtn: true
            })
            this.processedToCancelReservation();
        }
        else if (this.state.clickConfirmBtn) {
            this.processedToEditReservation();
        }
    }

    close = () => {
        if (this.state.isTimeOut) {
            history.push({
                pathname: "/OTPPage",
                state: { menu: "main" }
            });
        } else if (this.state.havingError) {
            history.push({
                pathname: "/MenuPage",
                state: { menu: "main" }
            });
        }
        this.setState({
            titleSavingChanges: null,
            msgSavingChanges: null,
            isSuccess: false,
            havingError: false,
            isTimeOut: false,
            clickCancelReserveBtn: false,
            //for successful status
            editableChildSeat: [],
            editableNumOfAdult: this.state.selectedAdultNumReserved,
            clickConfirmBtn: false,
            disableConfirmBtn: true
        })
    }

    render() {
        let state = this.state;
        let subthis = this;
        return (
            <div className='div-primary'>
                <LoadingModal isLoading={this.state.isLoading} />
                <ModalConfirmCancel showModal={this.state.msgSavingChanges != null} modalTitle={this.state.titleSavingChanges} modalMessage={this.state.msgSavingChanges} confirmButton={() => this.confirm()} closeModal={() => this.close()} isSuccess={this.state.isSuccess} isReserve={this.state.clickCancelReserveBtn || this.state.clickConfirmBtn} />
                <ModalAlert showModal={this.state.havingError} modalMessage={this.state.errorMsg} closeModal={() => this.close()} isSuccess={false} />

                <div className='container col-lg-6 col-md-6 col-sm-12 col-12' style={{ padding: 20 }}>
                    <div>
                        <Label className='label-normal'>Booking for</Label><br />
                        <Label className='label-title' style={{ padding: 0 }}>{this.state.reserveDate}</Label>
                        {(this.state.reservationStatus === 2 || this.state.reservationStatus === 3) &&
                            <Label className='label-normal'><br />ID: {this.state.reserveId}</Label>
                        }
                    </div>

                    <div>
                        <div style={{ marginTop: 30 }}>
                            <div className="row">
                                <div className="col-sm-6 col-6" style={{ textAlign: 'start' }}>
                                    <Label className='label-normal-normal'>Status</Label>
                                </div>
                                <div className="col-sm-6 col-6" style={{ textAlign: 'end', paddingRight: 20 }}>
                                    {(this.state.reservationStatus === 1 || this.state.reservationStatus === 2) &&
                                        <Label className='label-normal' style={{ color: this.state.reservationStatusColor }}>{this.state.reservationStatus === 1 ? 'Pending' : 'Successful'}</Label>
                                    }
                                    {(this.state.reservationStatus === 3 || this.state.reservationStatus === 4) &&
                                        <Label className='label-small-title' style={{ color: this.state.reservationStatusColor }}>{this.state.reservationStatus === 3 ? 'Cancelled' : 'Unsuccessful'}</Label>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3 col-3" style={{ textAlign: 'start' }}>
                                    <Label className='label-normal-normal'>Venue</Label>
                                </div>
                                <div className="col-sm-9 col-9" style={{ textAlign: 'end', paddingRight: 20 }}>
                                    <Label className='label-normal'>{this.state.selectedVenue}</Label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3 col-3" style={{ textAlign: 'start' }}>
                                    <Label className='label-normal-normal'>Timing</Label>
                                </div>
                                <div className="col-sm-9 col-9" style={{ textAlign: 'end', paddingRight: 20 }}>
                                    <Label className='label-normal'>{this.state.selectedService} - {this.state.selectedTiming}</Label>
                                </div>
                            </div>
                            {this.state.clickReleaseSeatBtn
                                ? <div className="row">
                                    <div className="col-sm-7 col-7" style={{ textAlign: 'start', paddingTop: 8 }}>
                                        <Label className='label-normal-normal' style={{ fontWeight: 500 }}>No. of adults attending</Label>
                                    </div>
                                    <div className="col-sm-5 col-5" style={{ textAlign: 'end', paddingRight: 20 }}>
                                        <div className="row" style={{ marginTop: 0, justifyContent: 'end' }}>
                                            <Button className='btn-minus' onClick={(event) => this.eventHandler("minusNumOfAdultBtn")}></Button>
                                            <Label style={{ border: '1px solid #CDCED2', borderRadius: 8, width: 50, marginLeft: 5, marginRight: 5, textAlign: 'center', paddingTop: 8 }}>{this.state.editableNumOfAdult}</Label>
                                            <Button className='btn-plus' onClick={(event) => this.eventHandler("plusNumOfAdultBtn")}></Button>
                                        </div>
                                    </div>
                                </div>
                                : <div className="row">
                                    <div className="col-sm-9 col-9" style={{ textAlign: 'start' }}>
                                        <Label className='label-normal-normal'>No. of adults attending</Label>
                                    </div>
                                    <div className="col-sm-3 col-3" style={{ textAlign: 'end', paddingRight: 20 }}>
                                        {state.reservationStatus === 2 &&
                                            <Label className='label-normal'>{this.state.selectedAdultNumReserved}</Label>
                                        }
                                        {(state.reservationStatus === 1 || state.reservationStatus === 4) &&
                                            <Label className='label-normal'>{this.state.selectedAdultNum}</Label>
                                        }
                                        {state.reservationStatus === 3 && (state.reserveId === null || state.reserveId === "") &&
                                            <Label className='label-normal'>{this.state.selectedAdultNum}</Label>
                                        }
                                        {state.reservationStatus === 3 && (state.reserveId !== null && state.reserveId !== "") &&
                                            <Label className='label-normal'>{this.state.selectedAdultNumReserved}</Label>
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        {this.state.selectedChildWithNameNCalss.length > 0 &&
                            <div style={{ textAlign: 'start', marginTop: 30 }}>
                                <Label className='label-normal'>{this.state.reservationStatus === 2 ? "Children Attending" : "Rock Kidz"}</Label>
                            </div>
                        }
                        <div className='row'>
                            {this.state.selectedChildWithNameNCalss.length > 0 && this.state.selectedChildWithNameNCalss.map(function (value, index) {
                                return <div className='col-lg-12 col-md-12 col-sm-12 col-12' key={index}>
                                    {state.clickReleaseSeatBtn
                                        ? <Button className='btn-primary' style={state.style.listBtnStyle} onClick={(e) => subthis.eventHandler("releaseChildBtn", value.ChildIdentity)}>
                                            {state.editableChildSeat !== null && state.editableChildSeat.includes(value.ChildIdentity)
                                                ?
                                                <div className='row' style={{ marginTop: 0 }}>
                                                    <Label className='label-small-300 col-lg-8 col-md-8 col-sm-8 col-8' style={{ color: '#CDCED2', textAlign: 'left', width: '80%', lineHeight: 2 }}><b>{value.ChildName}</b><br />{value.RegisteredClassName}</Label>
                                                    <Label className='label-normal-normal col-lg-4 col-md-4 col-sm-4 col-4' style={state.style.labelUnReleaseStyle}>Un-release</Label>
                                                </div>
                                                : <div className='row' style={{ marginTop: 0 }}>
                                                    <Label className='label-small-300 col-lg-8 col-md-8 col-sm-8 col-8' style={{ color: '#333333', textAlign: 'left', width: '80%', lineHeight: 2 }}><b>{value.ChildName}</b><br />{value.RegisteredClassName}</Label>
                                                    <Label className='label-normal-normal col-lg-4 col-md-4 col-sm-4 col-4' style={state.style.labelReleaseStyle}>Release</Label>
                                                </div>
                                            }
                                        </Button>
                                        : <div>
                                            {(state.reservationStatus === 1 || state.reservationStatus === 2) &&
                                                <Button className='btn-primary-border' style={state.style.listBtnStyle}>
                                                    <div className='row' style={{ marginTop: 0 }}>
                                                        <Label className='label-small-300 col-lg-8 col-md-8 col-sm-9 col-9' style={{ textAlign: 'left', width: '80%', lineHeight: 2 }}><b>{value.ChildName}</b><br />{value.RegisteredClassName}</Label>
                                                        <div className='col-lg-4 col-md-4 col-sm-3 col-3' style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: '20%' }}><img src={tick} alt="tick" /></div>
                                                    </div>
                                                </Button>
                                            }
                                            {(state.reservationStatus === 3 || state.reservationStatus === 4) &&
                                                <Button className='btn-primary-border' style={{ width: '100%', textAlign: 'left', padding: 15, lineHeight: 2, marginBottom: 20, backgroundColor: '#E5E5E5' }}>
                                                    <div className='row' style={{ marginTop: 0 }}>
                                                        <Label className='label-small-300 col-lg-8 col-md-8 col-sm-9 col-9' style={{ textAlign: 'left', width: '80%', lineHeight: 2 }}><b>{value.ChildName}</b><br />{value.RegisteredClassName}</Label>
                                                    </div>
                                                </Button>
                                            }
                                        </div>
                                    }
                                </div>
                            })
                            }
                        </div>
                    </div>
                    {(this.state.reservationStatus === 1 || this.state.reservationStatus === 2) &&
                        <div style={{ textAlign: 'left', marginTop: 50, marginBottom: 20 }}>
                            <Button className='btn-cancel-reservation label-normal' onClick={(event) => this.eventHandler("cancelReserveBtn", event)}>Cancel Booking</Button>
                        </div>
                    }
                </div>

                <div className="row" style={{ borderTop: "1px solid #E5E5E5", padding: 20, marginTop: 20, justifyContent: 'center' }}>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                        <Button className='btn-cancel' onClick={() => this.props.history.push('/ReservationsList')}>Back</Button>
                    </div>
                    {this.state.reservationStatus === 2 &&
                        <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                            {!this.state.clickReleaseSeatBtn
                                ? <Button className='btn-create' onClick={(event) => this.eventHandler("releaseReserveBtn", event)}>Edit Booking</Button>
                                : <Button className='btn-modal' style={{ width: '100%' }} disabled={this.state.disableConfirmBtn} onClick={(event) => this.eventHandler("confirmReserveBtn", event)}>Confirm</Button>
                            }
                        </div>
                    }
                </div>
            </div >
        );
    }
}