import React from 'react';
import { Button } from 'reactstrap';
import { history } from '../../components/_helpers/history.js';
import { ModalAlert } from '../../components/Common/Modal/Modal.js';

import { API } from 'aws-amplify';

export default class SideMenuPage extends React.Component {

    constructor(props) {
        super(props);
        let accessToken = window.localStorage.getItem('token');
        let idToken = window.localStorage.getItem('id_token');
        this.state = {
            accessToken: accessToken,
            hasBookingGroup: false,
            idToken: idToken,
            havingError: false,
            errorMsg: ""
        }
    }

    componentDidMount = () => {
        this.loadBookingList();
    }

    async loadBookingList() {
        this.setState({ isLoading: true });

        API
            .post('bookingapi', '/api/bookingGroup/loadDetails', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'queryStringParameters': {
                    'funcCall': 'bookingList'
                }
            })
            .then(response => {
                if (response.data !== null && response.data !== []) {
                    let list = [];
                    list = response.data;
                    this.setState({
                        hasBookingGroup: list.length > 0,
                        isLoading: false
                    })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            console.log(error.response.status)
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    reserveRKSeat = () => {
        // console.log(this.state.hasBookingGroup);
        if (this.state.hasBookingGroup) {
            history.push({
                pathname: "/NewReserveKidsAndAdultsSeats",
                state: { menu: "main" }
            });
        } else {
            history.push({
                pathname: "/NewBookingGroup",
                state: { fromReserve: true, menu: "main" }
            });
        }
    }

    reservationsList = () => {
        history.push({
            pathname: "/ReservationsList",
            state: { menu: "main" }
        });
    }

    bookingGroupList = () => {
        history.push({
            pathname: "/BookingGroupList",
            state: { menu: "main" }
        });
    }

    logout = () => {
        // Send a logout request to the API
        API
            .post('bookingapi', '/otp/gettingOtp', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'queryStringParameters': {
                    'type': "DeleteRedis"
                }
            })
            .then(response => {
                // console.log(response)
            })
            .catch(error => {
                this.setState({ isLoading: false });
                console.log(error);
            });

        // console.log('Sending a logout request to the API...');
        let base_SSO_URL = encodeURI("https://api-stg.newcreation.org.sg");
        let redirect_url = encodeURI("https://rkbooking-stg.newcreation.org.sg");
        let token = encodeURI(this.state.idToken);
        let url = base_SSO_URL + "/connect/endsession?post_logout_redirect_uri=" + redirect_url + "&id_token_hint=" + token;
        window.location = url;
    };

    destroy = () => {
        //clear the session
        history.push('/Login')
    };

    close = () => {
        this.setState({ havingError: false })
        history.push({
            pathname: "/OTPPage",
            state: { menu: "main" }
        });
    }

    render() {
        return (
            <div className='div-primary'>
                <ModalAlert showModal={this.state.havingError} modalMessage={this.state.errorMsg} closeModal={() => this.close()} isSuccess={false} />

                <div style={{ textAlign: 'right' }}>
                    <div className='col-lg-8 col-md-8 col-sm-8 col-12'>
                        <Button className='btn-sidemenu' onClick={this.reserveRKSeat}>Book Seats For Rock Kidz Classes</Button>
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-8 col-12'>
                        <Button className='btn-sidemenu' onClick={this.reservationsList}>My Booking Status</Button>
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-8 col-12'>
                        <Button className='btn-sidemenu' onClick={this.bookingGroupList}>My Booking Groups</Button >
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-8 col-12'>
                        <Button className='btn-sidemenu' onClick={this.logout}>Log Out</Button>
                    </div>
                </div>
            </div>
        )

    }
}
