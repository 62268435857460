import './App.css';
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from './components/_helpers/history';
import { PrivateRoute } from './components/PRoute/PrivateRoute';

import NotFound, { Forbidden } from './module/NotFound';
import MainPage from './module/MainPage/MainPage';
import SideMenuPage from './module/MenuPage/SideMenuPage';
import MenuPage from './module/MenuPage/MenuPage';
import BookingGroupDetails from './module/BookingGroup/BookingGroupDetails';
import BookingGroupList from './module/BookingGroup/BookingGroupList';
import ReserveRKNASeats from './module/ReserveRK/ReserveRKNASeats';
import ReservationsList from './module/ReserveRK/ReservationsList';
import ReservationView from './module/ReserveRK/ReservationView';
import OTPPage from './module/OTP/OTPPage';
import Redirect from './module/NoBooking/Redirect';
import Amplify from "aws-amplify";
import awsmobile from "./aws-exports";


Amplify.configure(awsmobile);

class App extends React.Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path='/' component={Redirect} />
                    <Route path='/Login' component={Redirect} />
                    <Route path='/OTPPage' component={Redirect} />
                    <PrivateRoute path='/MenuPage' component={Redirect} />
                    <PrivateRoute path='/SideMenuPage' component={Redirect} />
                    <PrivateRoute path='/NewBookingGroup' component={Redirect} />
                    <PrivateRoute path='/BookingGroup' component={Redirect} />
                    <PrivateRoute path='/BookingGroupList' component={Redirect} />
                    <PrivateRoute path='/NewReserveKidsAndAdultsSeats' component={Redirect} />
                    <PrivateRoute path='/ReserveKidsAndAdultsSeats' component={Redirect} />
                    <PrivateRoute path='/ReservationsList' component={Redirect} />
                    {/* <Route path='/Login' component={MainPage} />
                    <Route path='/OTPPage' component={OTPPage} />
                    <PrivateRoute path='/MenuPage' component={MenuPage} />
                    <PrivateRoute path='/SideMenuPage' component={SideMenuPage} />
                    <PrivateRoute path='/NewBookingGroup' component={BookingGroupDetails} />
                    <PrivateRoute path='/BookingGroup' component={BookingGroupDetails} />
                    <PrivateRoute path='/BookingGroupList' component={BookingGroupList} />
                    <PrivateRoute path='/NewReserveKidsAndAdultsSeats' component={ReserveRKNASeats} />
                    <PrivateRoute path='/ReserveKidsAndAdultsSeats' component={ReservationView} />
                    <PrivateRoute path='/ReservationsList' component={ReservationsList} /> */}
                    <PrivateRoute path='/Forbidden' component={Forbidden} />
                    <Route component={NotFound} />
                </Switch>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
