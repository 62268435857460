import React from 'react';
import { Button } from 'react-bootstrap';
import { Label } from 'reactstrap';
import { NewBootStrapInput } from '../../components/Common/InputControls/InputControls.js';
import { LoadingModal, ModalConfirmCancel, ModalAlert, ModalChangeBookingGRoup, ModalBookedAlert } from '../../components/Common/Modal/Modal.js';
import moment from 'moment';
import { history } from '../../components/_helpers/history.js';
import { API } from 'aws-amplify';

import tick from '../../image/tick_icon.svg';
import search from '../../image/search_icon.svg';

export default class ReserveRKNASeats extends React.Component {
    constructor(props) {
        super(props);
        let accessToken = window.localStorage.getItem('token');

        this.state = {
            accessToken: accessToken,
            isLoading: false,
            selectedBookingGroupID: null,
            selectedBookingGroupName: null,
            selectedBookingGroupMember: null,
            selectedBookingAdultNum: null,
            selectedBookingChildList: null,
            oriAvailableServiceList: [],//get the available list from server
            availableServiceList: [], //after filter
            selectedService: null,
            selectedVenueName: null,
            selectedServiceName: null,
            selectedRKStartTime: null,
            reserveDate: null,
            isChangingGroup: false,
            isShowingMore: true,
            selectedChildWithNameNCalss: [],
            msgSavingChanges: null,
            titleSavingChanges: null,
            isSuccess: false,
            showReserveSeatsBtn: false, //show pop out button as View Request after confirmation
            havingError: false,
            errorMsg: "",
            isTimeOut: false,
            isEmptyService: false,//true if user do not select the service after click reserve button            
            isChildHavingReservationRecords: false,//if user has booked for the week
            isAllChildHavingReservationRecords: false,
            tempBookedChildName: [],
            isFull: false,

            style: {
                serviceBtnStyle: { width: '100%', textAlign: 'left', padding: 15, lineHeight: 2, marginBottom: 20 },
            }
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    async loadData() {
        this.setState({ isLoading: true });

        API
            .post('bookingapi', '/api/bookingGroup/loadDetails', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'queryStringParameters': {
                    'funcCall': 'bookingList'
                }
            })
            .then(response => {
                // console.log(response)
                if (Array.isArray(response.data) && response.data.length > 0) {
                    response.data.forEach(subElement => {
                        var arrayChild = subElement.SelectedChild.split(',');
                        let groupName = subElement.NoofAdultAttend + " adults + " + arrayChild.length + " RK";
                        if (subElement.IsDefault) {
                            this.setState({
                                selectedBookingGroupID: subElement.BookingGroupID,
                                selectedBookingGroupName: subElement.BookingGroupName,
                                selectedBookingGroupMember: groupName,
                                selectedBookingAdultNum: subElement.NoofAdultAttend,
                                selectedBookingChildList: subElement.SelectedChild,
                            })
                        }
                    });
                    if (this.state.selectedBookingGroupID === null) {
                        let tempBookingGroup = response.data[0];
                        this.setState({
                            selectedBookingGroupID: tempBookingGroup.BookingGroupID,
                            selectedBookingGroupName: tempBookingGroup.BookingGroupName,
                            selectedBookingGroupMember: tempBookingGroup.NoofAdultAttend + " adults + " + tempBookingGroup.SelectedChild.split(',').length + " RK",
                            selectedBookingAdultNum: tempBookingGroup.NoofAdultAttend,
                            selectedBookingChildList: tempBookingGroup.SelectedChild,
                        })
                    }
                    this.loadBookingGroupDetails();
                }
                else {
                    this.setState({ isLoading: false })
                    history.push({
                        pathname: "/NewBookingGroup",
                        state: { fromReserve: true }
                    });
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    async loadBookingGroupDetails() {
        API
            .post('bookingapi', '/api/childrenList', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'body': {
                    'selectedChild': this.state.selectedBookingChildList
                },
                'queryStringParameters': {
                    'funcCall': 'selectedchildrenlist'
                }
            })
            .then(response => {
                // console.log(response)
                if (response.data !== null && response.data.length > 0) {
                    // console.log(response.data);
                    this.setState({
                        selectedChildWithNameNCalss: response.data
                    })

                    API
                        .post('bookingapi', '/api/reservation', {
                            'headers': {
                                'Authorization': this.state.accessToken
                            },
                            'response': 'true',
                            'queryStringParameters': {
                                'funcCall': 'checkSelectedChildForBookedStatus'
                            }
                        })
                        .then(response => {
                            // console.log(response);
                            if (response.data !== null && response.data !== "") {
                                let childHasBooked = response.data.split(',');
                                let isChildHavingReservationRecords = false;
                                let tempArraySelectedBookingChildList = this.state.selectedBookingChildList.split(',');
                                let tempBookedChildName = [];

                                this.state.selectedChildWithNameNCalss.forEach(obj => {
                                    if (childHasBooked.includes(obj.ChildIdentity)) {
                                        isChildHavingReservationRecords = true;
                                        tempArraySelectedBookingChildList = tempArraySelectedBookingChildList.filter(item => item !== obj.ChildIdentity)
                                        tempBookedChildName.push(obj.ChildName);
                                    }
                                })

                                if (isChildHavingReservationRecords) {
                                    this.setState({
                                        selectedBookingChildList: tempArraySelectedBookingChildList.join(),
                                        isChildHavingReservationRecords: true,
                                        msgSavingChanges: tempArraySelectedBookingChildList.length > 0 ? "The following Rock Kidz already have pending/successful bookings:" : "You have already made a booking/booking request. Do you wish to book additional seats for the English service only or change your booking group?",
                                        isAllChildHavingReservationRecords: tempArraySelectedBookingChildList.length === 0,
                                        tempBookedChildName: tempBookedChildName,
                                        isLoading: false
                                    })
                                }
                                else {
                                    this.loadSession();
                                }
                            }
                            else {
                                this.loadSession();
                            }
                        })
                        .catch(error => {
                            this.setState({ isLoading: false })
                            if (typeof error.response !== 'undefined' || error.response !== undefined) {
                                switch (error.response.status) {
                                    case 401:
                                        // console.log('no authorised')
                                        this.setState({
                                            isTimeOut: true,
                                            havingError: true,
                                            errorMsg: "Your session has expired"
                                        })
                                        break;
                                    default:
                                        this.setState({
                                            havingError: true,
                                            errorMsg: "Unable to process your request due to high traffic. Please try again later"
                                        })
                                        break;
                                }
                            }
                            else {
                                this.setState({
                                    isTimeOut: true,
                                    havingError: true,
                                    errorMsg: "Your session has expired"
                                })
                            }
                        });
                }
                else {
                    this.setState({
                        isLoading: false,
                        havingError: true,
                        errorMsg: "Unable to process your request. Please try again later"
                    })
                    console.log(response);
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    async loadSession() {
        API
            .post('bookingapi', '/api/rkservice', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'body': {
                    'selectedChild': this.state.selectedBookingChildList
                },
                'queryStringParameters': {
                    'funcCall': 'rkservice'
                }
            })
            .then(response => {
                // console.log(response.data);
                if (response.data !== null) {
                    let date = moment(response.data.EventDate, "MM/DD/YYYY hh:mm:ss").format("dddd, Do MMMM yyyy");
                    let serviceList = [];
                    let serviceListInOrder = [];
                    if (response.data.EventDetails.length > 0) {
                        response.data.EventDetails.forEach(subElement => {
                            serviceList.push({
                                EventSessionId: subElement.EventSessionId,
                                VenueName: subElement.VenueName,
                                ServiceName: subElement.ServiceName,
                                RKStartTime: subElement.RKStartTime,
                                AdultSeatsIsFull: subElement.AdultSeatsIsfull,
                                BuildingId: subElement.BuildingId
                            });
                        });

                        serviceList.sort((a, b) => new Date(a.RKStartTime) - new Date(b.RKStartTime));

                        let order = response.data.ListOfOrder; //"1,2,6"
                        const orderInArray = order.split(",");
                        orderInArray.forEach(elementOrder => {
                            serviceList.forEach(element => {
                                if (parseInt(element.BuildingId) === parseInt(elementOrder)) {
                                    serviceListInOrder.push(element);
                                }
                            });
                        });
                    }

                    this.setState({
                        reserveDate: date,
                        oriAvailableServiceList: serviceListInOrder,
                        availableServiceList: serviceListInOrder,
                        isLoading: false
                    })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    eventHandler = (id, event, value) => {
        switch (id) {
            case 'changeBtn':
                this.setState({ isChangingGroup: true });
                break;
            case 'showMoreBtn':
                if (this.state.isShowingMore === false) {
                    this.setState({ isShowingMore: true });
                }
                else {
                    this.setState({ isShowingMore: false });
                }
                break;
            case 'serviceBtn':
                this.setState({
                    selectedService: value.EventSessionId,
                    selectedServiceName: value.ServiceName,
                    selectedVenueName: value.VenueName,
                    selectedRKStartTime: value.RKStartTime,
                    isFull: value.AdultSeatsIsFull,
                    isEmptyService: false
                })
                break;
            case 'reserveBtn':
                if (this.state.selectedService === null) {
                    this.setState({
                        isEmptyService: true
                    })
                } else {
                    this.setState({
                        titleSavingChanges: 'Request Booking',
                        msgSavingChanges: 'Please click confirm to submit your booking request'
                    })
                }
                break;
            case 'tbxFilterService':
                let temp = event.target.value;
                if (temp === "" || temp === null) {
                    this.setState({ availableServiceList: this.state.oriAvailableServiceList });
                    return;
                }
                let tempAvailableServiceList = this.state.oriAvailableServiceList.filter(item => item.ServiceName.toLowerCase().includes(temp.toLowerCase()) || item.VenueName.toLowerCase().includes(temp.toLowerCase()))
                this.setState({ availableServiceList: tempAvailableServiceList })
                break;
            default:
                break;
        }
    }

    async onSaveData() {
        API
            .post('bookingapi', '/api/reservation', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'body': {
                    'bookingGroupId': this.state.selectedBookingGroupID,
                    'selectedChild': this.state.selectedBookingChildList,
                    'sessionId': this.state.selectedService,
                    'noofAdultAttend': this.state.isFull ? 0 : this.state.selectedBookingAdultNum
                },
                'queryStringParameters': {
                    'funcCall': 'makeReservation'
                }
            })
            .then(response => {
                // console.log(response);
                if (response.status === 200) {
                    this.setState({
                        titleSavingChanges: 'Request Submitted',
                        msgSavingChanges: 'Your booking request is being processed. You will receive an email after your booking has been processed.',
                        showReserveSeatsBtn: true,
                        isSuccess: true,
                        isLoading: false
                    })
                }
                else {
                    this.setState({
                        isLoading: false,
                        havingError: true,
                        errorMsg: "No matching children identities found!"
                    })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    confirm = () => {
        this.setState({ titleSavingChanges: null, msgSavingChanges: null, showReserveSeatsBtn: false })
        if (this.state.isSuccess) {
            this.props.history.push('/MenuPage');
        }
        else {
            // console.log("process to save data");
            this.setState({ isLoading: true });
            this.onSaveData();
        }
    }

    close = () => {
        if (this.state.isSuccess) {
            this.props.history.push('/ReservationsList');

        } else if (this.state.isAllChildHavingReservationRecords) {
            window.open("https://www.newcreation.org.sg/services/booking");

        } else if (this.state.isTimeOut) {
            history.push({
                pathname: "/OTPPage",
                state: { menu: "main" }
            });

        } else if (this.state.havingError) {
            history.push({
                pathname: "/MenuPage",
                state: { menu: "main" }
            });
        }
        this.setState({
            isSuccess: false,
            havingError: false,
            showReserveSeatsBtn: false,
            isChildHavingReservationRecords: false,
            isAllChildHavingReservationRecords: false,
            isChangingGroup: false,
            titleSavingChanges: null,
            msgSavingChanges: null
        })
    }

    cross = () => {
        history.push({
            pathname: "/MenuPage",
            state: { menu: "main" }
        });
    }

    confirmChangeGroup = (changeBookingGroup) => {
        this.setState({
            isChangingGroup: false,
            selectedBookingGroupID: changeBookingGroup.id,
            selectedBookingGroupName: changeBookingGroup.name,
            selectedBookingGroupMember: changeBookingGroup.group,
            selectedBookingAdultNum: changeBookingGroup.noofAdultAttend,
            selectedBookingChildList: changeBookingGroup.selectedChild,
            oriAvailableServiceList: [],
            availableServiceList: [],
            selectedService: null,
            isLoading: true
        }, () => {
            this.loadBookingGroupDetails();
        });
    }

    confirmBookedAlert = () => {
        if (this.state.isAllChildHavingReservationRecords) {
            this.setState({
                isSuccess: false,
                havingError: false,
                isChildHavingReservationRecords: false,
                isAllChildHavingReservationRecords: false,
                isChangingGroup: true,
                titleSavingChanges: null,
                msgSavingChanges: null
            })
        }
        else {
            this.setState({
                isChildHavingReservationRecords: false,
                msgSavingChanges: null,
                isLoading: true
            })
            this.loadSession();
        }
    }

    render() {
        let state = this.state;
        let subthis = this;
        let tempArraySelectedBookingChildList = this.state.selectedBookingChildList === null ? null : this.state.selectedBookingChildList.split(',');
        return (
            <div className='div-primary'>
                <LoadingModal isLoading={this.state.isLoading} />
                <ModalAlert showModal={this.state.havingError} modalMessage={this.state.errorMsg} closeModal={() => this.close()} isSuccess={false} />
                <ModalConfirmCancel showModal={this.state.msgSavingChanges != null && !this.state.isChildHavingReservationRecords} modalTitle={this.state.titleSavingChanges} modalMessage={this.state.msgSavingChanges} confirmButton={() => this.confirm()} closeModal={() => this.close()} isSuccess={this.state.isSuccess} isReserve={this.state.showReserveSeatsBtn} />
                <ModalChangeBookingGRoup showModal={this.state.isChangingGroup} confirm={this.confirmChangeGroup} closeModal={() => this.close()} bookingGroupID={this.state.selectedBookingGroupID} />
                <ModalBookedAlert showModal={this.state.isChildHavingReservationRecords} modalMessage={this.state.msgSavingChanges} confirmButton={() => this.confirmBookedAlert()} closeModal={() => this.close()} isAll={this.state.isAllChildHavingReservationRecords} tempBookedChildName={this.state.tempBookedChildName} cross={() => this.cross()} />

                <div className='container col-lg-6 col-md-6 col-sm-12 col-12' style={{ padding: 20 }}>
                    <div>
                        <Label className='label-normal'>Join us on</Label><br />
                        <Label className='label-title' style={{ padding: 0 }}>{this.state.reserveDate}</Label>
                    </div>
                    <div style={{ textAlign: 'start', paddingTop: 30 }}>
                        <div className="row">
                            <div className="col-sm-6 col-6" style={{ paddingTop: 8 }}>
                                <Label className='label-small-title'>Booking group</Label>
                            </div>
                            <div className="col-sm-6 col-6" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button className='btn-sidemenu' onClick={(e) => this.eventHandler("changeBtn")}>Change</Button>
                            </div>
                        </div>

                        <div className="row" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <Button className='btn-primary col-lg-12 col-md-12 col-sm-12 col-12' onClick={(e) => this.eventHandler("showMoreBtn")}>
                                <div className='row' style={{ marginTop: 0, padding: 5 }}>
                                    <Label className='label-normal col-lg-8 col-md-8 col-sm-7 col-7' style={{ textAlign: 'left', width: '70%', lineHeight: 2 }}><b>{this.state.selectedBookingGroupName}</b><br />{this.state.isShowingMore ? "" : this.state.selectedBookingGroupMember}</Label>
                                    <Label className='label-normal col-lg-4 col-md-4 col-sm-5 col-5' style={{ display: "flex", justifyContent: "flex-end", width: '30%', color: '#FF8943' }}>{this.state.isShowingMore ? "Show less" : "Show more"}</Label>

                                    {this.state.isShowingMore &&
                                        <div className='col-lg-12 col-md-12 col-sm-12 col-12' style={{ paddingLeft: 15 }}>
                                            <Label className='row' style={{ textAlign: 'left' }}>
                                                <div className="label-normal col-lg-3 col-md-3 col-sm-4 col-4">Adults</div>
                                                <div className="label-normal col-lg-9 col-md-9 col-sm-8 col-8" style={{ textAlign: 'left' }}>{this.state.selectedBookingAdultNum}</div>
                                            </Label>
                                            <Label className='row' style={{ textAlign: 'left' }}>
                                                <div className="label-normal col-lg-3 col-md-3 col-sm-4 col-4">RockKidz</div>
                                                <div className="label-normal col-lg-9 col-md-9 col-sm-8 col-8" style={{ textAlign: 'left' }}>
                                                    {this.state.selectedChildWithNameNCalss.map((element1, index) => {
                                                        return <div key={index}>
                                                            {tempArraySelectedBookingChildList.includes(element1.ChildIdentity)
                                                                ? <Label>{element1.ChildName + " (" + element1.RegisteredClassName + ")"}</Label>
                                                                : <span><Label>{element1.ChildName + " (" + element1.RegisteredClassName + ") "}</Label><Label style={{ color: 'red' }}>[Booked]</Label></span>
                                                            }
                                                        </div>
                                                    })
                                                    }
                                                </div>
                                            </Label>
                                        </div>
                                    }
                                </div>
                            </Button>
                        </div>

                        <div className="row" style={{ paddingTop: 10 }}>
                            <div className="col-sm-12 col-12">
                                <Label className='label-small-title'>Sunday Service Availability</Label><br /><br />
                                <Label className='label-normal'>Based on your booking group, the following services are available for you</Label><br /><br />
                                {this.state.isEmptyService
                                    ? <Label className='label-normal' style={{ color: '#dc3545' }}>Please select the service you would like to make a booking for</Label>
                                    : <Label className='label-normal'>Please select the service you would like to make a booking for</Label>
                                }
                            </div>

                            {this.state.oriAvailableServiceList.length > 0
                                ? <div style={{ width: '100%' }}>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 filterInput">
                                        <div className='row searchSection' style={{ marginTop: 0, padding: 5 }}>
                                            <NewBootStrapInput className='col-lg-10 col-md-10 col-sm-10 col-10'
                                                fgStyle={{ border: 0, outline: 'none', lineHeight: 2, width: '100%' }}
                                                type='text'
                                                onChange={(event) => this.eventHandler("tbxFilterService", event, "")}
                                                placeholderText={"Filter venue and timing"}
                                            />
                                            <img className='col-lg-1 col-md-2 col-sm-2 col-2' src={search} alt="search" color="#000" />
                                        </div>
                                    </div>

                                    <div className="row" style={{ padding: 10, marginLeft: 'auto', marginRight: 'auto', marginTop: 0 }}>
                                        {this.state.availableServiceList.map(function (value, index) {
                                            return <div className='col-lg-12 col-md-12 col-sm-12 col-12' key={index}>
                                                {state.selectedService === value.EventSessionId
                                                    ? <Button className='btn-primary-border' style={state.style.serviceBtnStyle} onClick={(event) => subthis.eventHandler("serviceBtn", event, value)}>
                                                        {value.AdultSeatsIsFull
                                                            ?
                                                            <div className='row' style={{ marginTop: 0 }}>
                                                                <div className='col-lg-8 col-md-8 col-sm-6 col-6' style={{ textAlign: 'left', width: '80%', lineHeight: 2 }}><b>{value.VenueName}</b><br />{value.ServiceName}<Label style={{ color: 'red' }}> [Adults Capacity FULL]</Label></div>
                                                                <div className='col-lg-4 col-md-4 col-sm-6 col-6' style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: '20%' }}><img src={tick} alt="logo" /></div>
                                                            </div>
                                                            :
                                                            <div className='row' style={{ marginTop: 0 }}>
                                                                <div className='col-lg-8 col-md-8 col-sm-6 col-6' style={{ textAlign: 'left', width: '80%', lineHeight: 2 }}><b>{value.VenueName}</b><br />{value.ServiceName}</div>
                                                                <div className='col-lg-4 col-md-4 col-sm-6 col-6' style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: '20%' }}><img src={tick} alt="logo" /></div>
                                                            </div>
                                                        }
                                                    </Button>
                                                    : <Button className='btn-primary' style={state.style.serviceBtnStyle} onClick={(event) => subthis.eventHandler("serviceBtn", event, value)}><b>{value.VenueName}</b><br />{value.ServiceName}{value.AdultSeatsIsFull && <Label style={{ color: 'red' }}> [Adults Capacity FULL]</Label>}</Button>
                                                }
                                            </div>
                                        })
                                        }
                                    </div>
                                </div>
                                :
                                <div style={{ border: "1px solid #D7D8DB", borderRadius: 8, padding: 20, margin: '30px auto 20px', textAlign: 'center', width: '95%' }}>
                                    <Label className='label-normal'><strong>Sorry</strong></Label><br />
                                    <Label className='label-small-300'>We are unable to accommodate your selected booking group at any of our venues</Label>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div style={{ borderTop: "1px solid #E5E5E5", padding: 20, textAlign: 'center' }}>
                    <Button className='btn-footer col-lg-5 col-md-5 col-sm-10 col-10' style={{ padding: 8, marginTop: 10 }} onClick={(e) => this.eventHandler("reserveBtn", e, "Please click confirm to place a request to reserve seats")}>Book Seats</Button><br /><br />
                    <Label className='label-small-300'>We will inform you of your booking status via email within a few hours</Label>
                </div>
            </div >
        )
    }
}