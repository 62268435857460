import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Label } from 'reactstrap';
import { NewCustomSelect } from '../InputControls/InputControls';
import Loader from 'react-loader-spinner';
import { API } from 'aws-amplify';

import './Modal.css';
import notice from '../../../image/notice_icon.svg';
import success from '../../../image/success_icon.svg';

function LoadingModal({ isLoading }) {
    return <Modal show={isLoading} backdrop='static' keyboard={false}>
        <Modal.Body style={{ textAlign: 'center', padding: 30 }}>
            <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
        </Modal.Body>
    </Modal>
}

export class ModalConfirmCancel extends Component {
    close = () => {
        this.props.closeModal();
    }
    confirm = () => {
        this.props.confirmButton();
    }
    render() {
        return <Modal style={{ paddingLeft: 17 }} show={this.props.showModal}>
            {this.props.isSuccess
                ? <div style={{ textAlign: 'center', padding: 15, marginTop: 10 }}><img src={success} alt="success" /></div>
                : <div className='row'></div>
            }
            <Modal.Title className='label-prompt-title' style={{ textAlign: 'center' }}>{this.props.modalTitle}</Modal.Title>
            <Modal.Body className='label-normal' style={{ textAlign: 'center', marginBottom: 20 }}>
                {(this.props.modalMessage !== undefined) ? this.props.modalMessage : ''}
            </Modal.Body>
            <Modal.Footer style={{ textAlign: 'center' }}>
                <div className='row' style={{ marginTop: 0 }}>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                        {this.props.isReserve &&
                            <Button className='btn-modal-cancel' style={{ width: '100%' }} onClick={this.close}>{this.props.isSuccess ? (this.props.modalTitle === 'Request Submitted' ? 'View Request' : 'Book Seats') : 'Cancel'}</Button>
                        }
                        {!this.props.isReserve && !this.props.isSuccess &&
                            <Button className='btn-modal-cancel' style={{ width: '100%' }} onClick={this.close}>Cancel</Button>
                        }
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                        {this.props.isReserve && this.props.modalTitle === "Cancel Booking"
                            ? <Button className='btn-modal' style={{ width: '100%', backgroundColor: '#EF4B48', border: '1px solid #EF4B48' }} onClick={this.confirm}>{'Confirm'}</Button>
                            : <Button className='btn-modal' style={{ width: '100%' }} onClick={this.confirm}>{this.props.isSuccess ? 'OK' : 'Confirm'}</Button>
                        }
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    }
}

export class ModalAlert extends Component {
    close = () => {
        this.props.closeModal();
    }

    render() {
        return <Modal show={this.props.showModal}>
            {this.props.isSuccess
                ? <div style={{ textAlign: 'center', padding: 15, marginTop: 10 }}><img src={success} alt="success" /></div>
                : <div style={{ textAlign: 'center', padding: 15, marginTop: 10 }}><img src={notice} alt="notice" /></div>
            }
            <Modal.Title className='label-prompt-title' style={{ textAlign: 'center' }}>Notice</Modal.Title>
            <Modal.Body className='label-normal' style={{ textAlign: 'center', marginBottom: 20 }}>
                {(this.props.modalMessage !== undefined) ? this.props.modalMessage : ''}
            </Modal.Body>
            <Modal.Footer style={{ textAlign: 'center' }}>
                <div className='row' style={{ marginTop: 0 }}>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                        <Button className='btn-modal' style={{ width: '100%' }} onClick={this.close}>OK</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    }
}

export class ModalBookedAlert extends Component {
    close = () => {
        this.props.closeModal();
    }
    confirm = () => {
        this.props.confirmButton();
    }
    cross = () => {
        this.props.cross();
    }
    render() {
        return <Modal show={this.props.showModal}>
            {this.props.isAll &&
                <div style={{ textAlign: 'right', paddingRight: 50, paddingTop: 15 }}><Button className='btn-cross' onClick={this.cross}></Button></div>
            }
            <div style={{ textAlign: 'center', padding: 12, marginTop: 10 }}><img src={notice} alt="notice" /></div>
            <Modal.Title className='label-prompt-title' style={{ textAlign: 'center' }}>Notice</Modal.Title>
            <Modal.Body className='label-normal' style={{ textAlign: 'center', marginBottom: 20 }}>
                {
                    (this.props.modalMessage !== undefined)
                        ? (this.props.tempBookedChildName !== undefined && !this.props.isAll)
                            ? <Label>{this.props.modalMessage}<br />{
                                this.props.tempBookedChildName.map((element, index) => {
                                    return <div key={index}>
                                        {element}<br />
                                    </div>
                                })
                            }Do you want to proceed with booking for the remaining children?</Label>
                            : this.props.modalMessage
                        : ''
                }
            </Modal.Body>
            <Modal.Footer style={{ textAlign: 'center' }}>
                <div className='row' style={{ marginTop: 0 }}>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                        <Button className='btn-modal-cancel' style={{ width: '100%' }} onClick={this.close}>{this.props.isAll ? "Book Seats For English Service" : "Cancel"}</Button>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                        <Button className='btn-modal' style={{ width: '100%' }} onClick={this.confirm}>{this.props.isAll ? "Change Booking Group" : "Confirm"}</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    }
}

export class ModalChangeBookingGRoup extends Component {
    constructor(props) {
        super(props);
        let accessToken = window.localStorage.getItem('token') //? window.localStorage.getItem('token') : 96960;

        this.state = {
            accessToken: accessToken,
            isLoading: false,
            bookingGroupID: this.props.bookingGroupID,
            bookingGroupOptions: [],
            bookingGroupFromDDL: null,
            selectedBookingGroup: null,
            selectedNoofAdults: null,
            selectedChildWithNameNCalss: []
        }
    }
    componentDidMount = () => {
        this.setState({
            bookingGroupID: this.props.bookingGroupID,
        });
        this.loadBookingList();
    }
    componentDidUpdate(prevProps) {
        if ((this.props.bookingGroupID !== prevProps.bookingGroupID || prevProps.showModal !== this.props.showModal) && this.props.showModal) {
            this.setState({ bookingGroupID: this.props.bookingGroupID });
            this.loadBookingList();
        }
    }

    async loadBookingList() {
        if (this.state.bookingGroupID === null) {
            this.setState({ bookingGroupID: this.props.bookingGroupID, isLoading: true })
        } else {
            this.setState({ isLoading: true })
        }
        API
            .post('bookingapi', '/api/bookingGroup/loadDetails', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'queryStringParameters': {
                    'funcCall': 'bookingList'
                }
            })
            .then(response => {
                if (Array.isArray(response.data) && response.data.length > 0) {
                    let bookingGroup = [];
                    let tempOption = [];
                    // console.log(response.data);
                    response.data.forEach((subElement) => {
                        var arrayChild = subElement.SelectedChild.split(',');
                        let groupName = subElement.NoofAdultAttend + " adults + " + arrayChild.length + " RK";
                        bookingGroup.push({
                            id: subElement.BookingGroupID,
                            name: subElement.BookingGroupName,
                            group: groupName,
                            noofAdultAttend: subElement.NoofAdultAttend,
                            selectedChild: subElement.SelectedChild,
                        });
                        tempOption.push({
                            value: subElement.BookingGroupID,
                            label: subElement.BookingGroupName
                        });
                    });

                    this.setState({
                        bookingGroupOptions: tempOption,
                        bookingGroupFromDDL: tempOption.filter((singleItem) => singleItem.value === this.state.bookingGroupID)[0],
                        selectedNoofAdults: this.state.bookingGroupID !== null ? bookingGroup.filter((a) => a.id === this.state.bookingGroupID)[0].noofAdultAttend : null,
                        selectedBookingGroup: bookingGroup.filter((a) => a.id === this.state.bookingGroupID)[0],
                    })

                    if (this.state.bookingGroupID !== null) {
                        API
                            .post('bookingapi', '/api/childrenList', {
                                'headers': {
                                    'Authorization': this.state.accessToken
                                },
                                'response': 'true',
                                'body': {
                                    'selectedChild': bookingGroup.find(a => a.id === this.state.bookingGroupID).selectedChild
                                },
                                'queryStringParameters': {
                                    'funcCall': 'selectedchildrenlist'
                                }
                            })
                            .then(response => {
                                if (response.data !== null && response.data.length > 0) {
                                    // console.log(response.data);
                                    this.setState({
                                        selectedChildWithNameNCalss: response.data
                                    })
                                }
                                this.setState({ isLoading: false })
                            })
                            .catch(error => {
                                this.setState({ isLoading: false })
                                console.log(error);
                            });
                    }
                }
                else {
                    this.setState({ isLoading: false })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                console.log(error);
            });
    }

    eventHandler = (event) => {
        let temp = event;
        this.setState({
            bookingGroupFromDDL: temp,
            bookingGroupID: temp.value
        }, () => {
            this.loadBookingList();
        });
    }

    close = () => {
        this.props.closeModal();
        var modal = document.getElementById('createModal');
        modal.style.display = 'none'
    }

    confirm = () => {
        this.props.confirm(this.state.selectedBookingGroup);
        var modal = document.getElementById('createModal');
        modal.style.display = 'none'
    }

    render() {
        return <Modal show={this.props.showModal} id={"createModal"}>
            <div className='row'></div>
            <Modal.Title className='label-prompt-title' style={{ textAlign: 'center' }}>Change Booking Group</Modal.Title>
            <Modal.Body style={{ textAlign: 'center', marginBottom: 20 }}>
                <Label className='label-normal'>Please select a booking group</Label>
                <div style={{ marginTop: 15 }}>
                    <NewCustomSelect
                        options={this.state.bookingGroupOptions}
                        value={this.state.bookingGroupFromDDL}
                        onChange={(event) => this.eventHandler(event)}
                    />
                </div>
                {this.state.isLoading
                    ? <div className='row' style={{ justifyContent: 'center' }}>
                        <div className='col-sm-12 col-12'>
                            <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
                        </div>
                    </div>
                    : <div style={{ paddingTop: 10, paddingLeft: 10 }}>
                        <Label className='row' style={{ textAlign: 'left' }}>
                            <div className='label-normal col-sm-3 col-3'>Adults</div>
                            <div className='label-small-300 col-sm-9 col-9'>{this.state.selectedNoofAdults}</div>
                        </Label>
                        <Label className='row' style={{ textAlign: 'left' }}>
                            <div className='label-normal col-sm-3 col-3'>RockKidz</div>
                            <div className='label-small-300 col-sm-9 col-9'>
                                {this.state.selectedChildWithNameNCalss.length > 0 && this.state.selectedChildWithNameNCalss.map((element, index) => {
                                    return <div key={index}>
                                        <Label>{element.ChildName + " (" + element.RegisteredClassName + ")"}
                                        </Label>
                                    </div>
                                })
                                }
                            </div>
                        </Label>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer style={{ textAlign: 'center' }}>
                <div className='row' style={{ marginTop: 0 }}>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                        <Button className='btn-modal-cancel' style={{ width: '100%' }} onClick={this.close}>Cancel</Button>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                        <Button className='btn-modal' style={{ width: '100%' }} onClick={() => this.confirm()} disabled={this.state.isLoading}>Confirm</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    }
}


export { LoadingModal }
