import React from 'react';
import { API } from 'aws-amplify'
import { LoadingModal, ModalAlert } from '../../components/Common/Modal/Modal.js'
import { Label } from 'reactstrap';
import { Button } from 'react-bootstrap';
import ncc_logo from '../../image/ncc_logo_big.png';
import noah_logo from '../../image/noah_logo.png';
import { history } from '../../components/_helpers/history.js';

export default class OTPPage extends React.Component {

    constructor(props) {
        super(props);
        let token = window.localStorage.getItem('token');

        this.state = {
            isLoading: false,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            disable: true,
            accessToken: token,
            message: null,
            otpSend: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount = () => {
        this.GettingOTP();
    }

    async GettingOTP() {
        this.setState({ isLoading: true });
        API
            .post('bookingapi', '/otp/gettingOtp', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'queryStringParameters': {
                    'type': "GetOTP"
                }
            })
            .then(response => {
                // console.log(response.data)
                this.setState({ isLoading: false });
                if (response.status === 200) {
                    let resMsg = response.data.Msg;
                    switch (response.data.Code) {
                        case 200:
                            this.setState({
                                otpSend: true,
                                message: resMsg.Message
                            })
                            break;
                        case 400:
                            this.setState({
                                message: resMsg.Message
                            })
                            break;
                        case 401:
                            let base_SSO_URL = encodeURI("https://api-stg.newcreation.org.sg");
                            let redirect_url = encodeURI("https://rkbooking-stg.newcreation.org.sg");
                            let response_type = encodeURI("code");
                            let client_id = encodeURI("b37e8a26-c26a-4183-90a3-16c09c96bebb");
                            let scope = encodeURI("profile+noahapi-userprofile+noahapi-member+offline_access+userid+openid+noahapi-authentication");

                            let loginURL = base_SSO_URL + "/connect/authorize?redirect_uri=" + redirect_url + "&response_type=" + response_type + "&client_id=" + client_id + "&scope=" + scope;
                            window.location = loginURL;
                            break;
                        default:
                            this.setState({
                                message: resMsg.message
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        message: response.data.Msg
                    })
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    isLoading: false
                });
                let base_SSO_URL = encodeURI("https://api-stg.newcreation.org.sg");
                let redirect_url = encodeURI("https://rkbooking-stg.newcreation.org.sg");
                let response_type = encodeURI("code");
                let client_id = encodeURI("b37e8a26-c26a-4183-90a3-16c09c96bebb");
                let scope = encodeURI("profile+noahapi-userprofile+noahapi-member+offline_access+userid+openid+noahapi-authentication");

                let loginURL = base_SSO_URL + "/connect/authorize?redirect_uri=" + redirect_url + "&response_type=" + response_type + "&client_id=" + client_id + "&scope=" + scope;
                window.location = loginURL;
            });
    }

    isIntegerInput = (event) => {
        if (event.key !== "Backspace" && event.key !== "Delete") {
            var theEvent = event || window.event;
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
            var regex = /[0-9]/;
            if (!regex.test(key)) {
                theEvent.returnValue = false;

                if (theEvent.preventDefault) {
                    theEvent.preventDefault();
                }
            }
        }
    }

    handleChange(value1, event) {
        this.setState({ [value1]: event.target.value });

        if (event.nativeEvent.inputType === "deleteContentBackward") {
            const next = event.target.tabIndex - 2;
            if (next > -1) {
                event.target.form.elements[next].focus();
            }
        }
        else if (event.target.value !== "" && event.target.value !== null) {
            const next = event.target.tabIndex;
            if (next < 6) {
                event.target.form.elements[next].focus();
            }
        }
    }

    async VerifyOTP() {
        this.setState({ isLoading: true });
        let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6;
        if (otp.length !== 6) {
            this.setState({
                isLoading: false,
                message: 'Please enter the 6-digit SMS OTP sent to your registered mobile number'
            })
        }
        else {
            API
                .post('bookingapi', '/otp/verifyOtp', {
                    'headers': {
                        'Authorization': this.state.accessToken
                    },
                    'response': 'true',
                    'queryStringParameters': {
                        'type': "VerifyingOTP",
                        'otp': otp
                    }
                })
                .then(response => {
                    this.setState({
                        otp1: "",
                        otp2: "",
                        otp3: "",
                        otp4: "",
                        otp5: "",
                        otp6: "",
                        isLoading: false
                    });
                    if (response.status === 200) {
                        // console.log(response.data.Msg)
                        switch (response.data.Code) {
                            case 200:
                                switch (response.data.Msg) {
                                    case true:
                                        history.push({
                                            pathname: "/MenuPage",
                                            state: { menu: "main" }
                                        });
                                        break;
                                    case false:
                                        this.setState({
                                            message: "Failed OTP Verification"
                                        })
                                        break;
                                    default:
                                        this.setState({
                                            message: response.data.Msg
                                        })
                                        break;
                                }
                                break;
                            case 401:
                                this.setState({
                                    message: response.data.Msg
                                });
                                break;
                            default:
                                this.setState({
                                    message: response.data.Msg
                                })
                                break;
                        }
                    }
                    else {
                        this.setState({
                            message: response.data.Msg
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        isLoading: false
                    });
                    let base_SSO_URL = encodeURI("https://api-stg.newcreation.org.sg");
                    let redirect_url = encodeURI("https://rkbooking-stg.newcreation.org.sg");
                    let response_type = encodeURI("code");
                    let client_id = encodeURI("b37e8a26-c26a-4183-90a3-16c09c96bebb");
                    let scope = encodeURI("profile+noahapi-userprofile+noahapi-member+offline_access+userid+openid+noahapi-authentication");

                    let loginURL = base_SSO_URL + "/connect/authorize?redirect_uri=" + redirect_url + "&response_type=" + response_type + "&client_id=" + client_id + "&scope=" + scope;
                    window.location = loginURL;
                });
        }
    }

    close() {
        this.setState({
            message: null,
            otpSend: false
        });
    }

    render() {
        return (
            <div style={{ marginTop: 5 }}>
                <LoadingModal isLoading={this.state.isLoading} />
                <ModalAlert showModal={this.state.message != null} modalMessage={this.state.message} closeModal={() => this.close()} isSuccess={this.state.otpSend} />

                <div style={{ margin: 5, textAlign: 'center', boxShadow: '0 3px 3px #e0e0d1' }}><img src={ncc_logo} alt="nccLogo" width="100" /></div>
                <div className='row'>
                    <div className='container col-lg-6 col-md-7 col-sm-11 col-11' style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <div className='div-otp'><img src={noah_logo} alt="noahLogo" width="80" /></div>
                        <div className='div-otp'><Label className='label-otp'>As a security enhancement to your NOAH account, we have introduced two-factor authentication (2FA) where you will need to enter a one-time PIN (OTP) in addition to your login PIN.</Label></div>
                        <div className='div-otp'><Label className='label-otp'>To access your account, please enter the 6-digit SMS OTP sent to your registered mobile number.</Label></div>
                        <div className='row'>
                            <form className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                <div className="otpContainer">
                                    <input
                                        name="otp1"
                                        type="tel"
                                        min="0"
                                        max="9"
                                        maxLength="1"
                                        autoComplete="off"
                                        className="otpInput"
                                        value={this.state.otp1}
                                        onChange={e => this.handleChange("otp1", e)}
                                        tabIndex="1"
                                        onKeyDown={e => this.isIntegerInput(e)}
                                    />
                                    <input
                                        name="otp2"
                                        type="tel"
                                        min="0"
                                        max="9"
                                        maxLength="1"
                                        autoComplete="off"
                                        className="otpInput"
                                        value={this.state.otp2}
                                        onChange={e => this.handleChange("otp2", e)}
                                        tabIndex="2"
                                        onKeyDown={e => this.isIntegerInput(e)}
                                    />
                                    <input
                                        name="otp3"
                                        type="tel"
                                        min="0"
                                        max="9"
                                        maxLength="1"
                                        autoComplete="off"
                                        className="otpInput"
                                        value={this.state.otp3}
                                        onChange={e => this.handleChange("otp3", e)}
                                        tabIndex="3"
                                        onKeyDown={e => this.isIntegerInput(e)}
                                    />
                                    <input
                                        name="otp4"
                                        type="tel"
                                        min="0"
                                        max="9"
                                        maxLength="1"
                                        autoComplete="off"
                                        className="otpInput"
                                        value={this.state.otp4}
                                        onChange={e => this.handleChange("otp4", e)}
                                        tabIndex="4"
                                        onKeyDown={e => this.isIntegerInput(e)}
                                    />
                                    <input
                                        name="otp5"
                                        type="tel"
                                        min="0"
                                        max="9"
                                        maxLength="1"
                                        autoComplete="off"
                                        className="otpInput"
                                        value={this.state.otp5}
                                        onChange={e => this.handleChange("otp5", e)}
                                        tabIndex="5"
                                        onKeyDown={e => this.isIntegerInput(e)}
                                    />
                                    <input
                                        name="otp6"
                                        type="tel"
                                        min="0"
                                        max="9"
                                        maxLength="1"
                                        autoComplete="off"
                                        className="otpInput"
                                        value={this.state.otp6}
                                        onChange={e => this.handleChange("otp6", e)}
                                        tabIndex="6"
                                        onKeyDown={e => this.isIntegerInput(e)}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='div-otp'><Label className='label-otp'>If you have any questions on this,<br />please contact us <a href="https://docs.google.com/forms/d/e/1FAIpQLSc6Gfc9B4howoJQ489jB2SUxgIK0TZzJtBmedeX3-Z0gmVjSw/viewform" target="_blank" rel="noopener noreferrer">here</a>.</Label></div>
                    </div>
                </div>
                <div className='row' style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <div className='col-lg-11 col-md-11 col-sm-11 col-11'>
                        <Button className='btn-otp-submit col-lg-5 col-md-7 col-sm-12 col-12' onClick={e => this.VerifyOTP()}>SUBMIT</Button>
                    </div>
                    <div className='col-lg-11 col-md-11 col-sm-11 col-11' style={{ padding: 20 }}>
                        <Button className='btn-otp-resend col-lg-5 col-md-7 col-sm-12 col-12' onClick={e => this.GettingOTP()}>Resend OTP</Button>
                    </div>
                </div>
            </div >
        )
    }
}