import React, { Component } from 'react';
import { Form, FormGroup, Col, Container } from 'react-bootstrap';


export default class NotFound extends Component {

    render() {
        return <div>
            <Container className='gridcontainer'>
                <Form className='mainDetailContainer'>
                    <FormGroup>
                        <Col sm={12}>
                            <label className='lbl_header_naming lbl_header_name_portion'
                                style={{ textAlign: 'center' }}>Not Found</label>
                        </Col>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }
}


export class Forbidden extends Component {

    render() {
        return <div>
            <Container className='gridcontainer'>
                <Form className='mainDetailContainer'>
                    <FormGroup>
                        <Col sm={12}>
                            <label className='lbl_header_naming lbl_header_name_portion'
                                style={{ textAlign: 'center'  }}>Forbidden Access</label>
                        </Col>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }
}