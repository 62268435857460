import React from 'react';
import queryString from 'query-string';
import { Button } from 'react-bootstrap';
import { NewBootStrapInput, NewCustomSelect, CustomSwitch } from '../../components/Common/InputControls/InputControls';
import { Label } from 'reactstrap';
import { LoadingModal, ModalConfirmCancel, ModalAlert, ModalBookedAlert } from '../../components/Common/Modal/Modal.js';
import { API } from 'aws-amplify';
import { history } from '../../components/_helpers/history.js';

import tick from '../../image/tick_icon.svg';

export default class EmptyBookingGroup extends React.Component {

    constructor(props) {
        super(props);
        let params = queryString.parse(this.props.location.search);
        let paramsState = this.props.history.location.state !== undefined && this.props.history.location.state !== null ? this.props.history.location.state.fromReserve : null;
        let accessToken = window.localStorage.getItem('token');

        this.state = {
            isLoading: false,
            bookingGroupId: Object.keys(params).length !== 0 ? (typeof params.ID !== undefined || params.ID !== undefined) ? params.ID : null : null,
            mode: Object.keys(params).length !== 0 ? (typeof params.mode !== undefined || params.mode !== undefined) ? params.mode : null : null,
            isAlertOn: paramsState !== null ? paramsState : false,
            pathName: this.props.location.pathname,

            accessToken: accessToken,
            childList: [],
            selectedChildList: null,
            selectedChildArray: [],
            bookingGroupName: '',
            noOfAdultAttending: null,
            isDefault: false,
            hasDefault: null,
            defaultBookingGroup: null,
            configNoOfAdultOptions: [],
            disableSWDefault: false,
            updateDefaultGroup: false,

            //validation 
            validateGroupName: null,
            validateNoOfAdult: null,
            isEmptyChildList: false,

            isSuccess: false, //confirm and save the data successfully
            msgSavingChanges: null, //use for saving confirmation and success 
            titleSavingChanges: null,
            showPromptMessage: false,
            msgUpdatingChanges: null,

            disable: false, //disable the edit function when it is in view mode
            havingError: false,
            errorMsg: "",
            isDeleted: false,
            isTimeOut: false,

            style: {
                listBtnStyle: { width: '100%', textAlign: 'left', padding: 15, lineHeight: 2, marginBottom: 20 },
                tbxStyle: { border: '1px solid #CDCED2', boxSizing: 'border-box', borderRadius: 8, height: 40, width: '100%', padding: 10 }
            }
        }

        this.eventHandler = this.eventHandler.bind(this);
    }

    componentDidMount = () => {
        if (!this.state.isAlertOn) {
            this.loadBookingGropDetails();
        } else {
            this.setState({
                bookingGroupName: "My Default Group",
                isDefault: true
            })
        }
    }

    async loadBookingGropDetails() {
        this.setState({
            isLoading: true,
            configNoOfAdultOptions: [{ value: 0, label: "0" }, { value: 1, label: "1" }, { value: 2, label: "2" }, { value: 3, label: "3" }, { value: 4, label: "4" }, { value: 5, label: "5" }],
            disable: this.state.mode === 'view' ? true : false,
        });

        API
            .post('bookingapi', '/api/childrenList', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'queryStringParameters': {
                    'funcCall': 'childrenlist'
                }
            })
            .then(response => {
                // console.log(response);
                if (response.data !== null && response.data.length > 0) {
                    this.setState({
                        childList: response.data
                    })
                }

                if (this.state.pathName !== "/NewBookingGroup") {
                    API
                        .post('bookingapi', '/api/bookingGroup/loadDetails', {
                            'headers': {
                                'Authorization': this.state.accessToken
                            },
                            'response': 'true',
                            'body': {
                                'bookingGroupId': this.state.bookingGroupId
                            },
                            'queryStringParameters': {
                                'funcCall': 'bookingDetails'
                            }
                        })
                        .then(response => {
                            // console.log(response);
                            if (response.data !== null) {
                                let subElement = response.data;
                                var arrayChild = subElement.SelectedChild.split(',');
                                let noOfAdultAttending = this.state.configNoOfAdultOptions.filter((singleItem) => singleItem.label === subElement.NoofAdultAttend.toString())[0];

                                this.setState({
                                    bookingGroupName: subElement.BookingGroupName,
                                    isDefault: subElement.IsDefault,
                                    selectedChildArray: arrayChild,
                                    noOfAdultAttending: noOfAdultAttending,
                                    hasDefault: subElement.HasDefault,
                                    defaultBookingGroup: subElement.BookingDefaultGroupName,
                                    isLoading: false
                                })
                            }
                        })
                        .catch(error => {
                            this.setState({ isLoading: false })
                            if (typeof error.response !== 'undefined' || error.response !== undefined) {
                                switch (error.response.status) {
                                    case 401:
                                        // console.log('no authorised')
                                        this.setState({
                                            isTimeOut: true,
                                            havingError: true,
                                            errorMsg: "Your session has expired"
                                        })
                                        break;
                                    default:
                                        this.setState({
                                            havingError: true,
                                            errorMsg: "Unable to process your request due to high traffic. Please try again later"
                                        })
                                        break;
                                }
                            }
                            else {
                                this.setState({
                                    isTimeOut: true,
                                    havingError: true,
                                    errorMsg: "Your session has expired"
                                })
                            }
                        });
                }
                else {
                    API
                        .post('bookingapi', '/api/bookingGroup/loadDetails', {
                            'headers': {
                                'Authorization': this.state.accessToken
                            },
                            'response': 'true',
                            'queryStringParameters': {
                                'funcCall': 'emptyBookingDetails'
                            }
                        })
                        .then(response => {
                            // console.log(response);
                            if (response.data !== null) {
                                let emptySubElement = response.data;

                                this.setState({
                                    hasDefault: emptySubElement.HasDefault,
                                    defaultBookingGroup: emptySubElement.BookingDefaultGroupName,
                                    isLoading: false
                                })
                            }
                            else {
                                this.setState({ isLoading: false })
                            }
                        })
                        .catch(error => {
                            this.setState({ isLoading: false })
                            if (typeof error.response !== 'undefined' || error.response !== undefined) {
                                switch (error.response.status) {
                                    case 401:
                                        // console.log('no authorised')
                                        this.setState({
                                            isTimeOut: true,
                                            havingError: true,
                                            errorMsg: "Your session has expired"
                                        })
                                        break;
                                    default:
                                        this.setState({
                                            havingError: true,
                                            errorMsg: "Unable to process your request due to high traffic. Please try again later"
                                        })
                                        break;
                                }
                            }
                            else {
                                this.setState({
                                    isTimeOut: true,
                                    havingError: true,
                                    errorMsg: "Your session has expired"
                                })
                            }
                        });
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    eventHandler = (id, event, value) => {
        switch (id) {
            case 'tbxGroupName':
                this.setState({ bookingGroupName: event.target.value });
                if (event.target.value === null || event.target.value === "") {
                    this.setState({ validateGroupName: 'Please enter Group booking Name' })
                } else {
                    this.setState({ validateGroupName: null })
                }
                break;

            case "noOfAdultAttending":
                if (event !== null) {
                    let temp = event;
                    this.setState({ noOfAdultAttending: temp, validateNoOfAdult: null })
                }
                else {
                    this.setState({ noOfAdultAttending: null, validateNoOfAdult: 'Please select No. of adults attending' })
                }
                break;

            case "swDefault":
                if (this.state.hasDefault !== null && this.state.hasDefault !== "" && event) {
                    let currentGroupName = this.state.bookingGroupName === '' ? "current booking group" : this.state.bookingGroupName;
                    if (this.state.bookingGroupId !== this.state.hasDefault) {
                        this.setState({
                            msgUpdatingChanges: "You have already created <" + this.state.defaultBookingGroup + "> as your default group. Please confirm if you want to set <" + currentGroupName + "> as your default group"
                        })
                    }
                    else {
                        this.setState({
                            isDefault: event,
                            updateDefaultGroup: false
                        });
                    }
                }
                else {
                    this.setState({
                        isDefault: event,
                        updateDefaultGroup: false
                    });
                }
                break;

            case "childBtn":
                let childId = value.ChildIdentity.toString();
                let temp = this.state.selectedChildArray;
                if (!temp.includes(childId)) {
                    temp.push(childId);
                    this.setState({ isEmptyChildList: false })

                } else {
                    let index = temp.indexOf(childId);
                    temp.splice(index, 1)
                }
                this.setState({ selectedChildArray: temp })
                break;

            case "deleteGroup":
                this.setState({ isDeleted: true })
                break;

            default:
                break;
        }
    }

    gettingSaveData = () => {
        let isFailed = false;
        if (this.state.bookingGroupName === null || this.state.bookingGroupName === "") {
            isFailed = true;
            this.setState({ validateGroupName: 'Please enter Group booking Name' })
        }
        if (this.state.noOfAdultAttending === null || this.state.noOfAdultAttending === "") {
            isFailed = true;
            this.setState({ validateNoOfAdult: 'Please select No. of adults attending' })
        }
        if (this.state.selectedChildArray.length < 1) {
            isFailed = true;
            this.setState({ isEmptyChildList: true })
        }
        if (isFailed) {
            return;
        } else {
            let templist = this.state.selectedChildArray.toString();
            this.setState({
                selectedChildList: templist,
                bookingGroupId: this.state.bookingGroupId === null ? 0 : this.state.bookingGroupId,
                titleSavingChanges: 'Saving Changes',
                msgSavingChanges: 'Please click confirm to save your changes',
                isSuccess: false,
                showPromptMessage: true
            })
        }
    }

    comfirmBookingGroupData = () => {
        this.setState({ titleSavingChanges: null, msgSavingChanges: null, showPromptMessage: false })

        if (this.state.isSuccess) {
            this.props.history.push('/BookingGroupList');
        }
        else {
            //process to save data
            this.setState({ isLoading: true })
            this.onSaveData()
        }
    }

    async onSaveData() {
        API
            .post('bookingapi', '/api/bookingGroup/loadDetails', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'body': {
                    'bookingGroupId': this.state.bookingGroupId,
                    'bookingGroupName': this.state.bookingGroupName,
                    'isDefault': this.state.isDefault ? 1 : 0,
                    'noofAdultAttend': this.state.noOfAdultAttending.label,
                    'selectedChild': this.state.selectedChildList,
                    'updateDefaultGroup': this.state.updateDefaultGroup
                },
                'queryStringParameters': {
                    'funcCall': 'postbookingDetails'
                }
            })
            .then(response => {
                this.setState({ isLoading: false })
                //console.log(response);
                if (response.status === 200) {
                    this.setState({
                        titleSavingChanges: 'Success',
                        msgSavingChanges: 'Your changes have been successfully saved',
                        isSuccess: true,
                        showPromptMessage: true
                    })
                }
                else {
                    this.setState({
                        havingError: true,
                        errorMsg: "No matching children identities found"
                    })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    async onDeleteGroup() {
        this.setState({ isLoading: true, isDeleted: false })
        API
            .post('bookingapi', '/api/bookingGroup/loadDetails', {
                'headers': {
                    'Authorization': this.state.accessToken
                },
                'response': 'true',
                'body': {
                    'bookingGroupId': this.state.bookingGroupId,
                },
                'queryStringParameters': {
                    'funcCall': 'deleteBookingDetails'
                }
            })
            .then(response => {
                this.setState({ isLoading: false })
                //console.log(response);
                if (response.status === 200) {
                    history.push({
                        pathname: "/BookingGroupList",
                        state: { menu: "main" }
                    });
                }
                else {
                    this.setState({
                        havingError: true,
                        errorMsg: "Delete Booking Group Unsuccessful"
                    })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                if (typeof error.response !== 'undefined' || error.response !== undefined) {
                    switch (error.response.status) {
                        case 401:
                            // console.log('no authorised')
                            this.setState({
                                isTimeOut: true,
                                havingError: true,
                                errorMsg: "Your session has expired"
                            })
                            break;
                        default:
                            this.setState({
                                havingError: true,
                                errorMsg: "Unable to process your request due to high traffic. Please try again later"
                            })
                            break;
                    }
                }
                else {
                    this.setState({
                        isTimeOut: true,
                        havingError: true,
                        errorMsg: "Your session has expired"
                    })
                }
            });
    }

    changeEditMode = () => {
        this.props.history.push('/BookingGroup?ID=' + this.state.bookingGroupId + '&mode=edit');
        this.setState({ mode: 'edit', disable: false })
    }

    close = () => {
        if (this.state.isAlertOn) {
            this.setState({ isAlertOn: false })
            this.loadBookingGropDetails();
        }
        else if (this.state.isTimeOut) {
            this.setState({ havingError: false, isTimeOut: false })
            history.push({
                pathname: "/OTPPage",
                state: { menu: "main" }
            });
        }
        else if (this.state.havingError) {
            this.setState({ havingError: false })
            history.push({
                pathname: "/MenuPage",
                state: { menu: "main" }
            });
        }
        else if (this.state.isDeleted) {
            this.setState({ isDeleted: false })
        }
        else {
            if (this.state.isSuccess) {
                this.props.history.push('/NewReserveKidsAndAdultsSeats');
            }
            this.setState({
                titleSavingChanges: null,
                msgSavingChanges: null,
                isSuccess: false,
                showPromptMessage: false,
                msgUpdatingChanges: null
            })
        }
    }

    confirmDefault = () => {
        this.setState({
            isDefault: true,
            updateDefaultGroup: true,
            msgUpdatingChanges: null
        })
    }

    render() {
        let state = this.state;
        let subthis = this;
        let tempSelectChildArray = this.state.selectedChildArray;

        return (
            <div className='div-primary'>
                <LoadingModal isLoading={this.state.isLoading} />
                <ModalConfirmCancel showModal={this.state.showPromptMessage} modalTitle={this.state.titleSavingChanges} modalMessage={this.state.msgSavingChanges} confirmButton={() => this.comfirmBookingGroupData()} closeModal={() => this.close()} isSuccess={this.state.isSuccess} isReserve={true} />
                <ModalAlert showModal={this.state.isAlertOn} modalMessage={'You will be required to set up a booking group before proceeding to make a booking request'} closeModal={() => this.close()} isSuccess={false} />
                <ModalAlert showModal={this.state.havingError} modalMessage={this.state.errorMsg} closeModal={() => this.close()} isSuccess={false} />
                <ModalBookedAlert showModal={this.state.hasDefault !== null && this.state.msgUpdatingChanges !== null} modalMessage={this.state.msgUpdatingChanges} confirmButton={() => this.confirmDefault()} closeModal={() => this.close()} />
                <ModalConfirmCancel showModal={this.state.isDeleted} modalTitle={'Delete Booking Group'} modalMessage={'Do you want to delete this booking group?'} confirmButton={() => this.onDeleteGroup()} closeModal={() => this.close()} isSuccess={false} isReserve={false} />

                <div>
                    <div className='container col-lg-6 col-md-6 col-sm-12 col-12' style={{ padding: 15 }}>
                        <div>
                            <Label className='label-title'>{this.state.bookingGroupId != null ? this.state.mode === 'edit' ? 'Edit Booking Group' : this.state.bookingGroupName : 'Create New Booking Group'}</Label>
                        </div>
                        <div style={{ textAlign: 'start' }}>
                            {this.state.mode !== 'view' &&
                                <div className='row' style={{ marginTop: 30 }}>
                                    <div className='col-sm-12 col-12'>
                                        <Label className='label-normal'>Name of booking group</Label>
                                    </div>
                                </div>
                            }
                            {this.state.mode !== 'view' &&
                                <div className='row'>
                                    <div className='col-sm-12 col-12'>
                                        <NewBootStrapInput
                                            fgStyle={this.state.style.tbxStyle}
                                            type='text'
                                            value={this.state.bookingGroupName}
                                            onChange={(event) => this.eventHandler("tbxGroupName", event)}
                                            validationState={this.state.validateGroupName}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="row" style={{ marginTop: 30 }}>
                                <div className="col-sm-7 col-7" style={{ paddingTop: 8 }}>
                                    <Label className='label-normal'>No. of adults attending</Label>
                                </div>
                                <div className="col-sm-5 col-5">
                                    <NewCustomSelect overFlowSelect
                                        options={this.state.configNoOfAdultOptions}
                                        value={this.state.noOfAdultAttending}
                                        onChange={(event) => this.eventHandler("noOfAdultAttending", event)}
                                        validationState={this.state.validateNoOfAdult}
                                        disabled={this.state.disable}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: 30 }}>
                                <div className="col-sm-12 col-12">
                                    <Label className='label-normal'>{this.state.mode === 'view' ? 'Children attending' : 'Kindly select the child for this booking group'}</Label>
                                </div>
                                {this.state.mode !== 'view' &&
                                    <div className="col-sm-12 col-12">
                                        <Label className='label-small'>You may select more than one</Label>
                                    </div>
                                }
                            </div>

                            {this.state.isEmptyChildList &&
                                <div className="row">
                                    <div className="col-sm-12 col-12">
                                        <Label className='label-small' style={{ color: '#dc3545' }}><b>Please select the child you are booking for</b></Label>
                                    </div>
                                </div>
                            }

                            {this.state.childList.length === 0 &&
                                <div className="row">
                                    <div className="col-sm-12 col-12">
                                        <Label className='label-normal' style={{ color: '#dc3545' }}>Please register a child before creating booking group. If you have just registered your child with Rock Kidz, kindly wait for an hour for his/her profile to appear in the system.</Label>
                                    </div>
                                </div>
                            }

                            <div className="row">
                                {this.state.childList.length > 0 && this.state.childList.map(function (value, index) {
                                    return <div className='col-lg-12 col-md-12 col-sm-12 col-12' key={index}>
                                        {tempSelectChildArray.length > 0 && tempSelectChildArray.includes(value.ChildIdentity.toString())
                                            ? <Button className='btn-primary-border' style={state.style.listBtnStyle} disabled={state.disable} onClick={(event) => subthis.eventHandler("childBtn", event, value)}>
                                                <div className='row' style={{ marginTop: 0 }}>
                                                    <Label className='col-lg-8 col-md-8 col-sm-9 col-9 label-normal' style={{ textAlign: 'left', width: '80%', lineHeight: 2 }}><strong>{value.ChildName}</strong><br />{value.RegisteredClassName}</Label>
                                                    <div className='col-lg-4 col-md-4 col-sm-3 col-3' style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: '20%' }}><img src={tick} alt="logo" /></div>
                                                </div>
                                            </Button>
                                            : <Button className='btn-primary' style={state.style.listBtnStyle} disabled={state.disable} onClick={(event) => subthis.eventHandler("childBtn", event, value)}><Label className='label-normal'><strong>{value.ChildName}</strong><br />{value.RegisteredClassName}</Label></Button>
                                        }
                                    </div>
                                })
                                }
                            </div>

                            <div className="row" style={{ marginTop: 20 }}>
                                <div className="col-sm-9 col-9">
                                    <Label className='label-normal'>Set it as default booking group?</Label>
                                </div>
                                <div className="col-sm-3 col-3">
                                    <CustomSwitch
                                        checked={this.state.isDefault}
                                        onChange={(event) => this.eventHandler("swDefault", event)}
                                        disabled={this.state.disable} />
                                </div>
                            </div>

                            <div style={{ textAlign: 'left', marginTop: 20, marginBottom: 20 }}>
                                <Button className='btn-cancel-reservation' disabled={this.state.bookingGroupId === null || this.state.mode === 'view'} onClick={(event) => this.eventHandler("deleteGroup", event)}>Delete Group</Button>
                            </div>
                        </div>

                    </div>

                    <div className="row" style={{ borderTop: "1px solid #E5E5E5", padding: 20, marginTop: 20, justifyContent: 'center' }}>
                        {this.state.mode === 'view'
                            ?
                            <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                                <Button className='btn-cancel' onClick={() => this.props.history.push('/BookingGroupList')}>Back</Button>
                            </div>
                            : <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                                <Button className='btn-cancel' onClick={() => this.props.history.push('/BookingGroupList')}>Cancel</Button>
                            </div>
                        }
                        {this.state.mode === 'view'
                            ?
                            <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                                <Button className='btn-modal' style={{ width: '100%' }} onClick={this.changeEditMode}>Edit</Button>
                            </div>
                            : <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                                <Button className='btn-create' onClick={this.gettingSaveData}>{this.state.mode === 'edit' ? 'Save' : 'Create'}</Button>
                            </div>
                        }
                    </div>
                </div>
            </div>

        )
    }
}